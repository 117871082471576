
(function () {
    'use strict';
    var View = function (_model, _tags, _framesHandler) {
        var self = this;
        var ariaLabel = cylindo.getModule('cylindo.aria.label').create({ model: _model });
        var baseTemplate = [
            '<div class="cylindo-viewer-container" tabindex="0">',
            '<ul style="display: none;"></ul>', 
            '<div class="cylindo-action-button-group right" style="display: none;" aria-label="' + ariaLabel.texts.ACTION_BUTTONS_GROUP + '" role="group">',
            '<a style="display: none" href="javascript:void(0)" class="fullscreen-button cylindo-action-button-icon cylindo-icon-fullscreen-off" aria-label="' + ariaLabel.texts.ENTER_FULLSCREEN + '" role="link"></a>',
            '<a style="display: none" href="javascript:void(0)" class="threesixty-button cylindo-action-button-icon cylindo-icon-zoom-off" aria-label="' + ariaLabel.texts.ENTER_ZOOM + '" role="link"></a>',
            '<a style="display: none" href="javascript:void(0)" class="threesixty-button-secondary cylindo-action-button-icon cylindo-icon-zoom-off" aria-label="' + ariaLabel.texts.EXIT_ZOOM + '" role="link"></a>',
            '</div>',
            '<div class="cylindo-loader" role="progresbar"></div>',
            '</div>',
        ];
        var loadingBarTemplate = [
            '<div class="cylindo-loading-bar" role="progressbar"></div>',
        ];
        var thumbnailsTemplate = [
            '<div class="cylindo-thumbnail-wrapper" style="display: none;">',
            '<div class="cylindo-thumbnail-slider left"><a href="javascript:void(0)" class="glyphicon glyphicon-menu-left prev" aria-label="' + ariaLabel.texts.THUMBS_LEFT + '" role="link"></a></div>',
            '<div class="cylindo-thumbnail-bar" role="presentation"></div>',
            '<div class="cylindo-thumbnail-slider right"><a href="javascript:void(0)" class="glyphicon glyphicon-menu-right next" aria-label="' + ariaLabel.texts.THUMBS_RIGHT + '" role="link"></a></div>',
            '</div>',
        ];
        var framesHandler = _framesHandler;
        var tags = _tags;
        var model = _model;

        var thumbnails = cylindo.getModule('thumbnails'); 
        var config = cylindo.getModule('cylindo.core.config');
        var viewerFactory = cylindo.getModule('cylindo.classes.viewer.factory');
        var zoom = cylindo.getModule('zoom');
        var loadingBar = cylindo.getModule('loadingBar');
        var util = cylindo.getModule('cylindo.core.util');
        var dom = cylindo.getModule('cylindo.dom').create();
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var presentations = cylindo.getModule('cylindo.helpers.viewer.presentations');
        var contentHelper = cylindo.getModule('cylindo.helpers.content.to.load');
        var viewerStatesHelper = cylindo.getModule('cylindo.helpers.viewer.states');
        var fullScreenBtn = cylindo.getModule('cylindo.fullscreen.btn');
        var ZoomBtn = cylindo.getModule('cylindo.zoom.btn');
        var network = cylindo.getModule('cylindo.core.network');
        var viewerStates = viewerStatesHelper.getInitialStates();
        var el = null;
        var getElement;
        var getElements;
        var logger = model.getLogger();
        var template = null;
        var zoomBtn = null;
        var zoomBtn2 = null;
        var viewerReady = false;
        var viewer = null;
        var list360 = null;
        var loaderEl = null;
        var placeholder = null;
        var viewerContainer = null;
        var actionButtons = null;
        var placeholderImg;
        var currentFeatures = null;
        var hidePlaceholder = false;
        var hideActionBtns = false;
        var focusOnFeatureChange = false;
        var customZoomContainer = model.get('customZoomContainer');
        var isCustomZoom = typeof customZoomContainer === 'string' ? document.getElementById(customZoomContainer) !== null : false;
        var clickTypeZoom = null;
        var inRotation = false;
        var preventAllEvents = {
            status: false
        };
        var tapped = false;
        var customImgClick = {};
        var customImgFrame = null;
        var customImgZoom = false;
        var viewerDetails;
        var pendingContent;
        var contentTypes;
        var customImageClass = 'cylindo-custom-image';
        var ARQuickLookBtn = null;
        var errorOnInit = false;
        var thumbLocations = model.getThumbLocations();
        var thumbLocation = model.get('thumbLocation');
        var thumbBarEl;
        var permanentlyHidden = 'permanently-hidden';
        var temporarilyHidden = 'temporarily-hidden';
        var viewerSeen = false;
        var seenEventsAttached = false;
        var lastTryToTriggerSeenCall = 0;
        var arModes = cylindo.getModule('cylindo.helpers.ar.modes');
        var arModal = null;

        this.id = null;
        this.initialized = false;
        this.currentState = null;

        this.render = render;
        this.destroy = destroy;
        this.showImage = showImage;
        this.hideImage = hideImage;
        this.on = pubsub.on;
        this.off = pubsub.off;
        this.trigger = pubsub.trigger;
        this.isRotating = isRotating;
        this.isPanning = isPanning;
        this.getThreesixtyMaxSize = getThreesixtyMaxSize;
        this.getCurrentThreesixtyIndex = getCurrentThreesixtyIndex;
        this.exitZoom = exitZoom;
        this.rotate = rotate;
        this.goToAngle = goToAngle;
        this.previousSlide = previousSlide;
        this.nextSlide = nextSlide;
        this.getState = getState;
        this.zoom = autoZoom;
        this.getArQuickLookUrl = getArQuickLookUrl;

        setState(viewerStates.GENERIC.UINITIALIZED);
        init();


        function init() {
            setState(viewerStates.GENERIC.INITIALIZING);
            self.id = ++View.counter;
            if (framesHandler && !framesHandler.initialized) {
                framesHandler.initialize(model);
            }
            if (model.get('cylindoContent') === true) {
                model.once(model.events.FEATURES_CHANGED, self.render);
                model.once(model.events.FEATURES_ERROR, onFeaturesError);
                model.on(model.events.FEATURES_ERROR, forwardFeaturesErrorEvent);
            }
            model.on(model.events.CHANGED, updateView);
            if (thumbLocation === thumbLocations.TOP || thumbLocation === thumbLocations.LEFT) {
                baseTemplate = loadingBarTemplate.concat(thumbnailsTemplate, baseTemplate);
            }
            else {
                baseTemplate = loadingBarTemplate.concat(baseTemplate, thumbnailsTemplate);
            }
            template = baseTemplate.join('');
            el = document.getElementById(model.get('containerID'));
            if (!el) {
                logger.error(model.get('containerID') + ' element doesn\'t exist');
                return;
            }
            el.setAttribute('role', 'region');
            el.setAttribute('aria-label', ariaLabel.texts.TITLE);
            el.setAttribute('title', model.get('title'));
            getElement = function (selector) {
                return el ? el.querySelector(selector) : null;
            };
            getElements = function (selector) {
                return el ? dom.querySelectorAll(el, selector) : null;
            };
            initRender();
            model.prepareToFetchFeatures();
            model.fetchFeatures();
            window.addEventListener('resize', updateViewport);
        }
        function updateView(evt, data) {
            if (data.prop === 'title') {
                el.setAttribute('title', data.value);
            }
            if (framesHandler && framesHandler.initialized &&
                framesHandler.propsToValidateOrder.indexOf(data.prop) !== -1) {
                if (zoom.initialized && !zoom.disabled) {
                    zoom.disable();
                }
                framesHandler.validateOrder(evt, data);
            }
            if (data.prop === 'ARBannerConfig') {
                if (ARQuickLookBtn) {
                    ARQuickLookBtn.tryToShow();
                }    
            }
        }
        function initRender() {
            var isMobile = util.browser.isMobile();
            var thumbLocation = model.get('thumbLocation');
            var thumbLocationBaseClass = 'thumb-location-';
            if (util.browser.equalOrLessThanIE10() || util.browser.equalOrLessThanSafari6()) {
                throw Error('This browser version is no longer supported by the Cylindo HD 360 Viewer.');
            }
            if (dom.height(el) > 0) {
                el.classList.add('has-height');
            }
            else {
                el.style.height = '512px';
            }
            el.classList.add('cylindo-wrapper');
            el.innerHTML = template;
            thumbBarEl = getElement('.cylindo-thumbnail-bar');
            loaderEl = getElement('.cylindo-loader');
            viewerContainer = getElement('.cylindo-viewer-container');
            actionButtons = getElement('.cylindo-action-button-group');
            if (viewerContainer) {
                if (model.get('thumbs') === true) {
                    viewerContainer.classList.add('has-thumbs');
                    viewerContainer.classList.add(thumbLocationBaseClass + thumbLocation);
                }
                if (model.get('outlineOnFocus')) {
                    viewerContainer.classList.add('outline-on-focus');
                }
                if (dom.height(viewerContainer) === 0 &&
                    el.classList.contains('has-height')) {
                    el.classList.remove('has-height');
                    el.style.height = '512px';
                }
            }

            initFullscreenBtn();
            initZoomBtn();

            if ((!fullScreenBtn.isAvailable() && !zoomBtn.isAvailable()) ||
                model.get('presentation') === 'stacked') {
                hideActionBtns = true;
                actionBtnsHandleHiddenClass(true, permanentlyHidden);
            }
            if (isMobile && model.get('focusOnFeatureChange') === true) {
                focusOnFeatureChange = true;
            }
            if (model.get('cylindoContent') !== true) { 
                self.render();
            }
        }

        function onTouchStart(evt) {
            var btnZoom;
            var btnZoom2;
            var frame;
            var target;
            var liTarget;
            if (evt) {
                target = evt.target;
                if ((target && target.tagName === 'VIDEO') ||
                    (target &&
                        target.tagName === 'LI' &&
                        target.classList &&
                        target.classList.contains('cylindo-video'))) {
                    return;
                }
            }
            if (evt &&
                evt.touches &&
                evt.touches.length > 1) {
                zoomBtn.addBlinkClass();
                return; 
            }
            if (zoom.initialized) {
                if (!tapped) {
                    tapped = setTimeout(function () {
                        tapped = false;
                    }, 300);
                }
                else {
                    evt.preventDefault();
                    evt.stopPropagation();
                    preventEvents();
                    clearTimeout(tapped);
                    tapped = false;
                    if (isStacked()) {
                        if (zoom.disabled) {
                            clickTypeZoom = 2;
                            liTarget = target.tagName === 'IMG' ?
                                target.parentElement :
                                target.tagName === 'LI' ?
                                    target : null;
                            frame = !fullScreenBtn.isEnabled() && liTarget ? liTarget : null;
                            btnZoom = frame ?
                                frame.querySelector('.cylindo-action-button-group .threesixty-button') : null;
                            btnZoom2 = frame ?
                                frame.querySelector('.cylindo-action-button-group .threesixty-button-secondary') : null;
                            toggleStackedZoom(null, {
                                frame: frame,
                                btnZoom: btnZoom,
                                btnZoom2: btnZoom2,
                                withoutCoords: true
                            });
                        }
                        else {
                            zoom.disable();
                        }
                    }
                    else {
                        if (zoom.disabled) {
                            clickTypeZoom = 2;
                            startZoom();
                        }
                        else {
                            zoom.disable();
                        }
                    }
                }
            }
        }
        function onTouchEnd() {
            zoomBtn.removeBlinkClass();
        }
        function onFeaturesError() {
            var fallback = model.get('fallbackImage');
            errorOnInit = true;
            hideActionBtns = true;
            actionBtnsHandleHiddenClass(true, permanentlyHidden);
            showPlaceholder(fallback);
        }
        function onFeaturesErrorFromInstance() {
            if (ARQuickLookBtn) {
                ARQuickLookBtn.onFeaturesError();
            }
            if (arModal !== null && arModal.hide) {
                arModal.hide(true);
            }
        }
        function showLoading(milliseconds, callback) {
            dom.fadeIn(loaderEl, milliseconds, callback);
        }
        function hideLoading(milliseconds, callback) {
            dom.fadeOut(loaderEl, milliseconds, callback);
        }
        function updateViewport() {
            if (!el || el.length === 0 || !list360 || list360.length === 0) {
                return;
            }
            try {
                var isFixedHeight = el.classList.contains('has-height');
                var child = list360.childNodes[0].childNodes[0];
                var parent = child ? child.parentNode : null;
                var img = new Image();
                var width = viewerContainer ? viewerContainer.clientWidth : 0;
                var elWidth;
                var elHeight;
                var height;
                var ratio;
                var videoRect;
                if (!list360) {
                    return;
                }
                if (child instanceof Image) {
                    img = child;
                    elWidth = img.naturalWidth;
                    elHeight = img.naturalHeight;
                    ratio = elHeight / elWidth;
                    tryToRecalculateHeight();
                }
                else if (parent && parent.classList && parent.classList.contains('cylindo-video')) {
                    videoRect = child.getBoundingClientRect();
                    elWidth = videoRect.width;
                    elHeight = videoRect.height;
                    ratio = elHeight / elWidth;
                    tryToRecalculateHeight();
                }
            }
            catch (ex) {
                logger.error(ex);
            }
            function tryToRecalculateHeight() {
                if (!isFixedHeight) {
                    height = (width > 0 && width < elWidth ? width * ratio : elHeight ? elHeight : 512);
                    if (viewerContainer) {
                        viewerContainer.style.height = height + 'px';
                    }
                    el.style.height = '';
                    if (thumbnails &&
                        thumbnails.initialized &&
                        thumbnails.ready &&
                        !areVerticalThumbs()) {
                        height += thumbnails.thumbsHeight();
                        el.style.height = height + 'px';
                        if (viewerContainer) {
                            viewerContainer.style.height = '';
                        }
                        el.classList.add('has-height');
                    }
                    else if (!model.get('thumbs') ||
                        (model.get('thumbs') && areVerticalThumbs())) {
                        el.style.height = height + 'px';
                        if (viewerContainer) {
                            viewerContainer.style.height = '';
                        }
                        el.classList.add('has-height');
                    }
                }
            }
        }
        /**
         * Render function for viewer instance
         * Get all configuration values once model is populated
         */
        function render(evt, data) {
            var allFrames = framesHandler.getAllFramesToBeLoaded();
            var loadingBar = null;

            if (!util.browser.isMobile() && util.browser.isSafari() && parseFloat(util.browser.browser.version, 10) < 11) {
                el.classList.add('is-safari-desktop');
            }
            if (util.browser.isSafari() || util.browser.isIOS()) {
                el.classList.add('is-safari-or-ios');
            }
            model.off(model.events.FEATURES_ERROR, onFeaturesError);
            currentFeatures = model.get('currentFeatures');
            if (allFrames.THREESIXTY.TO_BE_LOADED === 0 &&
                allFrames.ALTERNATE.LEN === 0) {
                showPlaceholder(model.get('fallbackImage'));
                return; 
            }
            createViewerInstance();
            if (model.get('thumbs') === true) {
                initThumbnails();
            }
            if (model.get('progressBar') === true) {
                try {
                    loadingBar = initLoadingBar();
                    loadingBar.startLoading();
                    viewer.on(viewer.events.IMG_LOADED, loadingBar.updateBar);
                    viewer.on(viewer.events.ALT_CONTENT_LOADED, loadingBar.updateBar);
                    if (thumbnails && thumbnails.initialized) {
                        thumbnails.on(thumbnails.events.IMG_LOADED, loadingBar.updateBar);
                    }
                }
                catch (ex) {
                    logger.info(ex);
                }
            }
            viewer.on(viewer.events.ALT_CONTENT_LOADED, sendAltContentTag);
            initZoom();
            self.initialized = true;
            self.trigger(self.events.READY);
            function createViewerInstance() {
                var options = {
                    el: viewerContainer,
                    alternateContent: allFrames.ALTERNATE.LIST,
                    frameCount: allFrames.THREESIXTY.TO_BE_LOADED,
                    currentFeatures: currentFeatures,
                    model: model,
                    logger: logger,
                    tags: tags,
                    hideZoomBtn: !zoomBtn.isAvailable(),
                    hideFullScreenBtn: !fullScreenBtn.isAvailable(),
                    dom: dom,
                    framesHandler: framesHandler,
                    ariaLabel: ariaLabel,
                    initializedWithError: data && data.firstFeaturesFailed ? true : false
                };
                if (viewerFactory && viewerFactory.initialized) {
                    return;
                }
                if (data && data.firstFeaturesFailed) {
                    forwardFirstFeaturesErrorEvent(data.firstSetOfFeatures);
                }
                viewerFactory = viewerFactory.create(options);
                viewerFactory.prepareViewerCreator();
                framesHandler.validateOrder();
                if (!viewer || !viewer.initialized) {
                    viewerDetails = viewerFactory.createViewerInstance();
                    viewer = viewerDetails.instance;
                    if (viewerDetails.instance !== null &&
                        viewerDetails.presentation !== null) {
                        pendingContent = contentHelper.getContent(framesHandler, viewerDetails);
                        contentTypes = contentHelper.getContentTypes();
                        viewerStates = viewerStatesHelper.getAllPossibleStates(viewerDetails.presentation);
                        switch (viewerDetails.presentation) {
                            case presentations.THREESIXTY.NAME:
                                subscribeToThreesixtyEvents(viewerDetails.instance);
                                break;
                            case presentations.CAROUSEL.NAME:
                                subscribeToCarouselEvents(viewerDetails.instance);
                                break;
                            case presentations.STACKED.NAME:
                                subscribeToStackedEvents(viewerDetails.instance);
                                break;
                            case presentations.SINGLE.NAME:
                                subscribeToSingleEvents(viewerDetails.instance);
                                break;
                            case presentations.CUSTOM.NAME:
                                subscribeToCustomEvents(viewerDetails.instance);
                                break;
                        }
                    }
                    else {
                        logger.log('Cannot suscribe to viewer instance events.');
                    }
                }
            }
        }
        function initLoadingBar() {
            var opts = {
                model: model,
                container: el,
                dom: dom,
                framesHandler: framesHandler,
            };
            return loadingBar.create(opts);
        }
        function showPlaceholder(url) {
            var fallbackImage = model.get('fallbackImage');
            var isFallback = url === fallbackImage;
            if (isFallback && url === null) {
                logger.error('Valid image must be provided as fallback image.');
            }
            placeholderImg = new Image();
            placeholderImg.addEventListener('load', onPlaceholderLoad, false);
            placeholderImg.onerror = util.fallback.loadFallbackImage.bind({
                img: placeholderImg,
                model: model,
                errorText: 'Fallback image not found: '
            });
            placeholderImg.alt = 'Placeholder image';
            placeholderImg.src = isFallback ? '' : url ? url : '';
            placeholderImg.classList.add('cylindo-placeholder', 'opacity-one');
            if (model.get('thumbs') === true) {
                placeholderImg.classList.add('has-thumbs');
            }
        }

        function onPlaceholderLoad() {
            if (placeholder) {
                placeholder.src = prepareImgSrc(placeholderImg.src);
                if (hidePlaceholder) {
                    dom.remove(placeholder);
                }
                setTimeout(function () {
                    placeholderImg.removeEventListener('load', onPlaceholderLoad, false);
                    placeholderImg = null;
                }, 0);
            }
            else {
                hideLoading(400, function () {
                    if (model.get('thumbs') === true && viewerContainer) {
                        viewerContainer.classList.add('has-thumbs');
                    }
                    if (util.browser.isIE()) {
                        placeholderImg.style.width = placeholderImg.naturalWidth + 'px';
                        placeholderImg.style.height = placeholderImg.naturalHeight + 'px';
                    }
                    if (viewerContainer) {
                        viewerContainer.appendChild(placeholderImg);
                    }
                    placeholder = getElement('.cylindo-placeholder');
                    if (hidePlaceholder && placeholder) {
                        placeholder.style.display = 'none';
                    }
                    setTimeout(function () { 
                        if (placeholderImg) {
                            placeholderImg.removeEventListener('load', onPlaceholderLoad, false);
                            placeholderImg = null; 
                        }
                    }, 0);
                });
            }
        }
        function initZoom() {
            var config = {
                el: viewerContainer,
                list: list360,
                model: model,
                tags: tags,
                dom: dom,
                framesHandler: framesHandler,
                ariaLabel: ariaLabel,
            };
            if (!zoom.initialized) {
                zoom = zoom.create(config);
            }
            zoom.on(zoom.events.ENABLED, onZoomEnabled);
            zoom.on(zoom.events.DISABLED, onZoomDisabled);
            zoom.on(zoom.events.ZOOM, onZoomMoved);
        }
        function onZoomEnabled(evt, data) {
            var zoomOverlapsThumbs = model.get('zoomOverlapsThumbs');
            setElementsVisibility(data, false);
            if (zoomOverlapsThumbs && thumbnails && thumbnails.initialized && !thumbnails.isHidden) {
                if (isCustomZoom && !zoom.isOverlappingViewer()) {
                    logger.log('Thumb bar not hidden when custom zoom container is not overlapping the viewer');
                }
                else {
                    thumbnails.hide();
                }
            }
            setZoomInOutState(data.index, data.li, true);
            delete data.index;
            delete data.li;
            self.trigger(self.events.ZOOM_ENTER, data);
            if (viewer && viewer.initialized) {
                viewer.hideTooltip();
            }
        }
        function onZoomDisabled(evt, data) {
            var zoomOverlapsThumbs = model.get('zoomOverlapsThumbs');
            setElementsVisibility(data, true);
            if (zoomOverlapsThumbs && thumbnails && thumbnails.initialized && thumbnails.isHidden && !thumbnails.featuresChanging) {
                if (isCustomZoom && !zoom.isOverlappingViewer()) {
                    logger.log('Thumb bar not hidden when custom zoom container is not overlapping the viewer');
                }
                else {
                    thumbnails.show();
                }
            }
            enableViewer();
            zoomBtn.removeEventsToBtn();
            zoomBtn.off(zoomBtn.events.CLICK, zoom.zoomIn);
            zoomBtn.off(zoomBtn.events.TOUCHSTART, cancelEventPropagation);
            zoomBtn.off(zoomBtn.events.TOUCHEND, zoom.zoomIn);
            zoomBtn2.removeEventsToBtn();
            zoomBtn2.off(zoomBtn2.events.CLICK, zoom.zoomOut);
            zoomBtn2.off(zoomBtn2.events.TOUCHSTART, cancelEventPropagation);
            zoomBtn2.off(zoomBtn2.events.TOUCHEND, zoom.zoomOut);
            zoomBtn2.hide();
            if (!isStackedAndNotFS()) {
                subscribeToZoomBtnEvents();
            }
            setZoomInOutState(data.index, data.li, false);
            delete data.index;
            delete data.li;
            self.trigger(self.events.ZOOM_EXIT, data);
        }
        function setElementsVisibility(data, show) {
            var currImg = null;
            var currTooltip = null;
            var visibilityValue = show ? '' : 'hidden';
            if (isStackedAndNotFS()) {
                if (data && data.li) {
                    currImg = data.li.querySelector('img');
                    currTooltip = data.li.querySelector('.cylindo-drag-tooltip');
                    if (currImg) {
                        currImg.style.visibility = visibilityValue;
                    }
                    if (currTooltip) {
                        currTooltip.style.visibility = visibilityValue;
                    }
                }
            }
        }
        function onZoomMoved(evt, data) {
            self.trigger(self.events.ZOOM, data);
        }
        function disableViewer(data) {
            if (viewer &&
                viewer.initialized &&
                (!isCustomZoom || fullScreenBtn.isEnabled())) {
                viewer.disable(data);
            }
            else if (viewer &&
                viewer.initialized &&
                !zoom.isOverlappingViewer() &&
                !fullScreenBtn.isEnabled()) {
                viewer.preventRotation();
            }
            viewer.hideTooltip();
        }
        function enableViewer() {
            if (viewer && viewer.initialized) {
                hideLoading(0, function () {
                    viewer.mouseState = 0; 
                    viewer.enable();
                    zoomBtn.setZoomInIcon();
                    if (viewer.tooltip && !viewer.tooltip.el.classList.contains('cylindo-tooltip-empty-text')) {
                        viewer.showTooltip();
                    }
                    if (customImgZoom) {
                        addCustomImgEvents();
                        if (!isStackedAndNotFS()) {
                            viewer.removeMouseCallbacks();
                        }
                    }
                });
            }
        }
        function startZoom(evt, data) {
            var liActive = list360.querySelector('li.active');
            var active = (data && data.frame) ? data.frame : liActive;
            var isAlternativeImg = viewer.viewerPresentation === 'custom' ||
                (active && active.getAttribute('data-is-alternate-image') ?
                    +active.getAttribute('data-is-alternate-image') : 0) === 1 ?
                true : false;
            var isCustomImg = active.classList.contains(customImageClass);
            if (!canZoom(evt, data)) {
                return;
            }
            if (evt && (evt === viewer.events.CLICKED || evt === viewer.events.CUSTOM_IMG_CLICKED)) {
                clickTypeZoom = 1;
            }
            if (placeholder) {
                dom.remove(placeholder);
            }
            if (zoom && zoom.initialized) {
                disableViewer(data);
                if ((isAlternativeImg || isCustomImg)) {
                    if (!isStacked() ||
                        (isStacked() &&
                            fullScreenBtn &&
                            fullScreenBtn.initialized &&
                            fullScreenBtn.isEnabled())) {
                        showLoading(200, null);
                    }
                }
                zoom.startZoom(data, fullScreenBtn.isEnabled(), clickTypeZoom);
                handleZoomButton(data);
            }
            clickTypeZoom = null;
        }
        function canZoom(evt, data) {
            if (zoom && zoom.initialized) {
                return zoom.canZoom.call({
                    preventAllEvents: preventAllEvents,
                    evt: evt,
                    data: data,
                    list360: list360,
                    viewer: viewer,
                    customImageClass: customImageClass,
                    isStackedAndNotFS: isStackedAndNotFS(),
                    customImgZoom: customImgZoom
                });
            }
            return false;
        }
        function handleZoomButton(data) {
            var activeIndex, isAlternativeImg, isCustomImg, alternateContentZoom, addZoomOutClass;
            var active = (data && data.frame) ? data.frame : list360 ? list360.querySelector('li.active') : null;
            if (!active) {
                return;
            }
            activeIndex = active && active.dataset ? active.dataset.index : active.getAttribute('data-index');
            isAlternativeImg = viewer.viewerPresentation === 'custom' ||
                (active.getAttribute('data-is-alternate-image') ?
                    +active.getAttribute('data-is-alternate-image') : 0) === 1 ?
                true : false;
            isCustomImg = active.classList.contains(customImageClass);
            alternateContentZoom = model.get('alternateContentZoom');
            addZoomOutClass = false;
            if (util.browser.isMobile() &&
                ((isAlternativeImg && alternateContentZoom) ||
                    (isCustomImg && alternateContentZoom) ||
                    (viewerDetails && presentations &&
                        viewerDetails.presentation !== presentations.THREESIXTY.NAME))) {
                addZoomOutClass = true;
            }
            if (zoomBtn &&
                zoomBtn2 &&
                model.get('maxZoom') === 'mixed' &&
                framesHandler.is360Frame(activeIndex)) {
                zoomBtn2.setZoomOutIcon(true);
                if (zoomBtn2.isAvailable()) {
                    zoomBtn2.show();
                }
                removeAllZoomBtnEvents();
                zoomBtn.addEventsToBtn();
                zoomBtn.on(zoomBtn.events.CLICK, zoom.zoomIn);
                zoomBtn.on(zoomBtn.events.TOUCHSTART, cancelEventPropagation);
                zoomBtn.on(zoomBtn.events.TOUCHEND, zoom.zoomIn);
                zoomBtn2.addEventsToBtn();
                zoomBtn2.on(zoomBtn2.events.CLICK, zoom.zoomOut);
                zoomBtn2.on(zoomBtn2.events.TOUCHSTART, cancelEventPropagation);
                zoomBtn2.on(zoomBtn2.events.TOUCHEND, zoom.zoomOut);
            }
            else {
                zoomBtn.setZoomOutIcon(addZoomOutClass);
            }
        }
        function initARQuickLookBtn() {
            var isQRModal = model.get('arMode') === arModes.QR;
            var arConfig = {
                parent: viewerContainer,
                text: '', 
                dom: dom,
                model: model,
                preventEvents: preventEvents,
                settings: config,
                linkButton: true,
                tags: tags,
                util: util,
                ariaLabel: ariaLabel,
            };
            if (model.get('ARQuickLook') && !ARQuickLookBtn) {
                ARQuickLookBtn = cylindo.getModule('cylindo.button.ar').create(arConfig);
                renderARQuickLookBtn();
            }
            function renderARQuickLookBtn() {
                var viewerContainer = el ? el.querySelector('.cylindo-viewer-container') : null;
                var modalText = isQRModal ? model.get('QRCodeTxt') : model.get('ARLoadingTxt');
                var modalTitle = isQRModal ? model.get('QRCodeTitleTxt') : '';
                var ARBackToViewTxt = isQRModal ? model.get('ARBackToViewTxt') : '';
                var modalErrorText = model.get('ARNotAvailableTxt');
                arModal = cylindo.getModule('ar.modal').create({
                    parent: isQRModal ? viewerContainer.parentElement : document.body,
                    ARBackToViewTxt: ARBackToViewTxt,
                    title: modalTitle,
                    text: modalText,
                    errorText: modalErrorText,
                    dom: dom,
                    preventEvents: preventEvents,
                    util: util,
                    type: isQRModal ? cylindo.getModule('ar.modal').types.QR : cylindo.getModule('ar.modal').types.AR,
                });
                arModal.render();
                ARQuickLookBtn.render();
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_BUTTON_READY, ARQuickLookReady);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_BUTTON_ERROR, ARQuickLookError);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_LAUNCHED, tryToCloseFullScreen);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_SHOW_MODAL, showARModal);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_HIDE_MODAL, hideARModal);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_ACTION_BTN_TAPPED, ARActionBtnTapped);
                ARQuickLookBtn.on(ARQuickLookBtn.events.AR_SHOW_ERROR_MODAL, handleErrorModal);
                function showARModal(evt, data) {
                    if (model.get('arMode') === arModes.QR) {
                        arModal.remove();
                        arModal.setParent(fullScreenBtn.isEnabled() ? viewerContainer : viewerContainer.parentElement);
                        arModal.render();
                    }
                    arModal.show(null, true);
                }
                function hideARModal(evt, data) {
                    arModal.hide(true);
                }
            }
            function ARQuickLookReady(evt, data) {
                if (isQRModal) {
                    arModal.updateCode(data);
                }
                self.trigger(self.events.AR_BUTTON_READY, data);
            }
            function handleErrorModal() {
                arModal.showError();
            }
            function ARQuickLookError(evt, data) {
                self.trigger(self.events.AR_BUTTON_ERROR, data);
            }
            function tryToCloseFullScreen(evt, data) {
                if (fullScreenBtn.isEnabled() && model.get('arMode') !== arModes.QR) {
                    fullScreenBtn.close();
                }
            }
            function ARActionBtnTapped(evt, data) {
                self.trigger(self.events.AR_ACTION_BTN_TAPPED, data);
            }            
        }
        function getArQuickLookUrl(callback) {
            if (typeof callback !== 'function') {
                logger.error('getArQuickLookUrl method must receive a function as callback.');
                return;
            }
            if (!model.get('ARQuickLook') || !ARQuickLookBtn) {
                logger.error('ARQuickLook not enabled or it doesn\'t exist.');
                return;
            }
            if (model.get('ARQuickLook') && ARQuickLookBtn) {
                callback(ARQuickLookBtn.getArQuickLookUrl());
            }
        }
        function subscribeToThreesixtyEvents(instance) {
            instance.on(instance.events.FIRST_FRAME_COMPLETE, onFirstFrameComplete);
            instance.on(instance.events.READY_TO_BE_SHOWN, onViewerReadyToBeShown);
            instance.on(instance.events.READY, onViewerReady);
            instance.on(instance.events.DOWNLOAD_COMPLETE, onDownloadComplete);
            instance.on(instance.events.START, onThreesixtyStart);
            instance.on(instance.events.END, onThreesixtyEnd);
            instance.on(instance.events.IMG_LOADED, onImageLoaded);
            instance.on(instance.events.ALT_CONTENT_LOADED, onAltContentLoaded);
            instance.on(instance.events.FRAME_ACTIVATED, onFrameActivatedTryToShowLoading);
            instance.on(instance.events.FEATURES_CHANGED, onFeaturesChangedOrNoWarning);
            instance.on(instance.events.CURRENT, onCurrentChange);
            instance.on(instance.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
            instance.on(instance.events.FEATURES_CANCELED, onFeaturesCanceled);
            instance.on(instance.events.FEATURES_ERROR, onFeaturesErrorFromInstance);
            model.on(model.events.FEATURES_NO_WARNING, onFeaturesChanged);
            instance.on(instance.events.ALT_CONTENT_REMOVED, onAltContentRemoved);
        }
        function onThreesixtyStart(evt, data) {
            self.trigger(self.events.START_SPIN, data);
            toggleRotation();
        }
        function onThreesixtyEnd(evt, data) {
            self.trigger(self.events.END_SPIN, data);
            toggleRotation();
        }
        function subscribeToSingleEvents(instance) {
            instance.on(instance.events.FIRST_FRAME_COMPLETE, onFirstFrameComplete);
            instance.on(instance.events.READY_TO_BE_SHOWN, onViewerReadyToBeShown);
            instance.on(instance.events.READY, onViewerReady);
            instance.on(instance.events.DOWNLOAD_COMPLETE, onDownloadComplete);
            instance.on(instance.events.IMG_LOADED, onImageLoaded);
            instance.on(instance.events.ALT_CONTENT_LOADED, onAltContentLoaded);
            instance.on(instance.events.FRAME_ACTIVATED, onFrameActivatedTryToShowLoading);
            instance.on(instance.events.FEATURES_CHANGED, onFeaturesChangedOrNoWarning);
            instance.on(instance.events.CURRENT, onCurrentChange);
            instance.on(instance.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
            instance.on(instance.events.FEATURES_CANCELED, onFeaturesCanceled);
            instance.on(instance.events.FEATURES_ERROR, onFeaturesErrorFromInstance);
            model.on(model.events.FEATURES_NO_WARNING, onFeaturesChanged);
            instance.on(instance.events.ALT_CONTENT_REMOVED, onAltContentRemoved);
        }
        function subscribeToCarouselEvents(instance) {
            instance.on(instance.events.FIRST_FRAME_COMPLETE, onFirstFrameComplete);
            instance.on(instance.events.READY_TO_BE_SHOWN, onViewerReadyToBeShown);
            instance.on(instance.events.READY, onViewerReady);
            instance.on(instance.events.DOWNLOAD_COMPLETE, onDownloadComplete);
            instance.on(instance.events.PREV, onCarouselPrev);
            instance.on(instance.events.NEXT, onCarouselNext);
            instance.on(instance.events.IMG_LOADED, onCarouselImageLoaded);
            instance.on(instance.events.ALT_CONTENT_LOADED, onAltContentLoaded);
            instance.on(instance.events.FRAME_ACTIVATED, onFrameActivatedTryToShowLoading);
            instance.on(instance.events.FEATURES_CHANGED, onFeaturesChangedOrNoWarning);
            instance.on(instance.events.CURRENT, onCurrentChange);
            instance.on(instance.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
            instance.on(instance.events.FEATURES_CANCELED, onFeaturesCanceled);
            instance.on(instance.events.FEATURES_ERROR, onFeaturesErrorFromInstance);
            model.on(model.events.FEATURES_NO_WARNING, onFeaturesChanged);
            instance.on(instance.events.ALT_CONTENT_REMOVED, onAltContentRemoved);
        }
        function onCarouselImageLoaded(evt, data) {
            self.trigger(self.events.IMG_LOADED);
            tryToRemoveLoader(evt, data);
        }

        function onCarouselPrev(evt, data) {
            self.trigger(self.events.PREV, data);
            setStateWithActive();
        }
        function onCarouselNext(evt, data) {
            self.trigger(self.events.NEXT, data);
            setStateWithActive();
        }
        function subscribeToStackedEvents(instance) {
            instance.on(instance.events.FIRST_FRAME_COMPLETE, onFirstFrameComplete);
            instance.on(instance.events.READY_TO_BE_SHOWN, onViewerReadyToBeShown);
            instance.on(instance.events.READY, onViewerReady);
            instance.on(instance.events.DOWNLOAD_COMPLETE, onDownloadComplete);
            instance.on(instance.events.IMG_LOADED, onCarouselImageLoaded);
            instance.on(instance.events.ALT_CONTENT_LOADED, onAltContentLoaded);
            instance.on(instance.events.FRAME_ACTIVATED, onFrameActivatedTryToShowLoading);
            instance.on(instance.events.FEATURES_CHANGED, onFeaturesChangedOrNoWarning);
            instance.on(instance.events.CURRENT, onCurrentChange);
            instance.on(instance.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
            instance.on(instance.events.TOGGLE_ZOOM, toggleStackedZoom);
            instance.on(instance.events.FULLSCREEN_BTN_ADDED, addEventListenerToFSBtn);
            instance.on(instance.events.FULLSCREEN_BTN_REMOVED, removeEventListenerToFSBtn);
            instance.on(instance.events.FEATURES_CANCELED, onFeaturesCanceled);
            instance.on(instance.events.FEATURES_ERROR, onFeaturesErrorFromInstance);
            model.on(model.events.FEATURES_NO_WARNING, onFeaturesChanged);
            instance.on(instance.events.ALT_CONTENT_REMOVED, onAltContentRemoved);
        }


        function addEventListenerToFSBtn(evt, data) {
            if (data && data.btnFullScreen) {
                subscribeToFullScreenEvents(data.btnFullScreen);
            }
        }
        function removeEventListenerToFSBtn(evt, data) {
            if (data && data.btnFullScreen) {
                removeAllFullScreenEvents(data.btnFullScreen);
            }
        }
        function modifyStackedBehivor(isFullScreen) {
            if (viewer &&
                viewer.initialized &&
                isStacked()) {
                if (isFullScreen) {
                    fullScreenBtn.setBtn(getButton('.fullscreen-button'));
                    zoomBtn.setBtn(getButton('.threesixty-button'));
                    zoomBtn2.setBtn(getButton('.threesixty-button-secondary'));
                    if (!zoomBtn.isAvailable() &&
                        !fullScreenBtn.isAvailable()) {
                        actionBtnsHandleHiddenClass(true, permanentlyHidden);
                    }
                    else {
                        hideActionBtns = false;
                        actionBtnsHandleHiddenClass(false, permanentlyHidden);
                    }
                }
                else {
                    actionBtnsHandleHiddenClass(true, permanentlyHidden);
                    hideActionBtns = true;
                }
                showActionButtons();
                viewer.enableFullScreen(isFullScreen);
            }
        }

        function toggleStackedZoom(evt, data) {
            var zoomWasOpened = false;
            if (zoom && zoom.initialized) {
                if (zoomBtn.btn === data.btnZoom &&
                    !zoom.disabled &&
                    (model.get('maxZoom') === 'mixed' && framesHandler.is360Frame(zoom.getFrameIndex()))) {
                    return;
                }
                else if ((zoomBtn.btn !== data.btnZoom) &&
                    !zoom.disabled) {
                    zoomWasOpened = true;
                    zoom.on(zoom.events.DISABLED, doToggle);
                    zoom.disable();
                }
                else {
                    doToggle();
                }
            }
            function doToggle() {
                setBtns();
                if (zoomWasOpened) {
                    zoom.off(zoom.events.DISABLED, doToggle);
                }
                if (zoom.disabled) {
                    if (data &&
                        fullScreenBtn.isEnabled()) {
                        data.reloadZoomContainer = true;
                    }
                    startZoom(evt, data);
                }
                else {
                    zoom.disable();
                }
            }
            function setBtns() {
                var zoomButton;
                var zoomButton2;
                zoomButton = fullScreenBtn.isEnabled() ?
                    zoomBtn.btn :
                    data.btnZoom;
                zoomButton2 = fullScreenBtn.isEnabled() ?
                    zoomBtn2.btn :
                    data.btnZoom2;
                zoomBtn.setBtn(zoomButton);
                zoomBtn2.setBtn(zoomButton2);
            }
        }
        function subscribeToCustomEvents(instance) {
            instance.on(instance.events.FIRST_FRAME_COMPLETE, onFirstFrameComplete);
            instance.on(instance.events.READY_TO_BE_SHOWN, onCustomViewerReadyToBeShown);
            instance.on(instance.events.READY, onCustomViewerReady);
            instance.on(instance.events.ALT_CONTENT_LOADED, onAltContentLoaded);
            instance.on(instance.events.FRAME_ACTIVATED, onFrameActivatedTryToShowLoading);
            instance.on(instance.events.ALT_CONTENT_REMOVED, onAltContentRemoved);
            instance.on(instance.events.DOWNLOAD_COMPLETE, onDownloadComplete);
            instance.on(instance.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
        }
        function onCustomViewerReadyToBeShown(evt, data) {
            var active, statesObj;
            list360 = el.querySelector('.cylindo-custom-list');
            active = getActiveDetails();
            if (active && active.li) {
                if (active.li.classList.contains('cylindo-video')) {
                    actionBtnsHandleHiddenClass(true, temporarilyHidden);
                    viewer.hideTooltip(true);
                }
                else {
                    actionBtnsHandleHiddenClass(false, temporarilyHidden);
                    if (typeof viewer.tooltip !== 'undefined' &&
                        !active.li.classList.contains('cylindo-img-not-found')) {
                        viewer.showTooltip();
                    }
                }
            }
            statesObj = getStatesObject(active.index, active.li);
            if (statesObj) {
                setState(statesObj.FRAME);
            }
            hideLoading(400, null);
        }

        function onCustomViewerReady() {
            var viewerContainer = el ? el.querySelector('.cylindo-viewer-container') : null;
            if (viewerContainer) {
                viewerContainer.addEventListener('touchstart', onTouchStart);
                viewerContainer.addEventListener('touchend', onTouchEnd);
            }
            list360 = el.querySelector('.cylindo-custom-list');
            hideLoading(400, function () {
                if (placeholder) {
                    dom.remove(placeholder);
                    list360.classList.add('opacity-one');
                }
                else {
                    hidePlaceholder = true;
                    list360.classList.add('opacity-one');
                }
                if (zoom.initialized) {
                    zoom.setViewerList(list360);
                }
                if (viewer.initialized) {
                    viewer.off(viewer.events.CLICKED, startZoom);
                    viewer.on(viewer.events.CLICKED, startZoom);
                }
                if (thumbnails && thumbnails.initialized) {
                    thumbnails.render();
                }
                showActionButtons();
            });
            handleViewerReady();
        }
        function onAltContentRemoved() {
            if (viewerDetails &&
                viewerDetails.presentation === presentations.CUSTOM.NAME) {
                showLoading(0, null);
            }
            if (thumbnails && thumbnails.initialized) {
                thumbnails.render();
            }
        }
        function setContentDownloaded(evt, data) {
            var contentType = data && data.contentType ? data.contentType : null;
            var downloadFinished = true;
            var contentToValidate = pendingContent.dowanloaded ?
                pendingContent.contentToUpdate : pendingContent.contentToLoad;
            var i;
            var dataToBeSent = {
                c: pendingContent.totalFrames,
            };
            var thumbsDetails = viewer && viewer.initialized ?
                viewer.getThumbsDetails() : {};
            var updatedStartTime = viewer && viewer.initialized ?
                viewer.viewerUpdatedStartTime : 0;
            thumbsDetails.acc = thumbsDetails.ac;
            delete thumbsDetails.ac;
            if (contentType === null) {
                return;
            }
            for (i = 0; i < contentToValidate.length; i++) {
                if (contentToValidate[i].type === contentType) {
                    contentToValidate[i].downloaded = true;
                    contentToValidate[i].elapsed = data.elapsed || Date.now() - updatedStartTime;
                }
                if (contentToValidate[i].downloaded === false) {
                    downloadFinished = false;
                }
            }
            if (downloadFinished && contentToValidate.length) {
                for (i = 0; i < contentToValidate.length; i++) {
                    dataToBeSent[contentToValidate[i].type] = contentToValidate[i].elapsed;
                    if (pendingContent.dowanloaded) {
                        contentToValidate[i].elapsed = 0;
                        contentToValidate[i].downloaded = false;
                    }
                }
                dataToBeSent = util.object.extend({}, dataToBeSent, thumbsDetails);
                if (ARQuickLookBtn &&
                    ARQuickLookBtn.initialized) {
                    ARQuickLookBtn.btnExistsPromise.then(function () {
                        dataToBeSent = util.object.extend({}, dataToBeSent, {
                            ar: true,
                        });
                        triggerEvt();
                        ARQuickLookBtn.sendInitialTags();
                    }, function () {
                        triggerEvt();
                    });
                }
                else {
                    triggerEvt();
                }
            }
            function triggerEvt() {
                if (!pendingContent.dowanloaded) {
                    tags.send(tags.events.LOADED, dataToBeSent);
                    triggerSeenTag();
                    pendingContent.dowanloaded = true;
                }
            }
        }
        function onViewerReadyToBeShown(evt, data) {
            var milliseconds = isStackedAndNotFS() ? 500 : 0;
            var statesObj, active;
            list360 = el ? el.querySelector('.cylindo-threesixty-list') : null;
            active = getActiveDetails();
            if (errorOnInit && !isStacked()) {
                if (active && active.li &&
                    active.li.classList &&
                    !active.li.classList.contains('cylindo-img-not-found')) {
                    errorOnInit = false;
                    if (hideActionBtns &&
                        (fullScreenBtn.isAvailable() || zoomBtn.isAvailable())) {
                        hideActionBtns = false;
                        actionBtnsHandleHiddenClass(false, permanentlyHidden);
                    }
                }
            }
            if (util.browser.isMobile() &&
                model.get('zoomButton') &&
                !isStacked()) {
                viewer.handleZoomButton(active.li, active.index, null);
            }
            hideLoading(milliseconds, function () {
                tryToExecuteFn(updateViewport);
                if (model.get('ARQuickLook') && !ARQuickLookBtn) {
                    initARQuickLookBtn();
                }
                if (placeholder) {
                    dom.remove(placeholder);
                    list360.classList.add('opacity-one');
                }
                else {
                    list360.classList.add('opacity-one');
                    hidePlaceholder = true;
                }
                if (active && active.li) {
                    if (active.li.classList.contains('cylindo-video')) {
                        actionBtnsHandleHiddenClass(true, temporarilyHidden);
                        viewer.hideTooltip(true);
                    }
                    else {
                        actionBtnsHandleHiddenClass(false, temporarilyHidden);
                    }
                }
                statesObj = getStatesObject(active.index, active.li);
                if (statesObj) {
                    setState(statesObj.FRAME);
                }
                function tryToExecuteFn(cb) {
                    try {
                        cb();
                    }
                    catch (ex) { }
                }
            });
        }
        function onViewerReady() {
            var milliseconds = isStackedAndNotFS() ? 500 : 0;
            var viewerContainer = el ? el.querySelector('.cylindo-viewer-container') : null;
            if (viewerContainer) {
                viewerContainer.addEventListener('touchstart', onTouchStart);
                viewerContainer.addEventListener('touchend', onTouchEnd);
            }
            else {
                return;
            }
            list360 = el ? el.querySelector('.cylindo-threesixty-list') : null;
            hideLoading(milliseconds, function () {
                if (placeholder) {
                    dom.remove(placeholder);
                }
                else {
                    hidePlaceholder = true;
                }
                list360.classList.add('opacity-one');
                if (zoom.initialized) {
                    zoom.setViewerList(list360);
                }
                if (viewer && viewer.initialized) {
                    if (isStackedAndNotFS()) {
                        viewer.off(viewer.events.CLICKED, toggleStackedZoom);
                        viewer.on(viewer.events.CLICKED, toggleStackedZoom);
                    }
                    else {
                        viewer.off(viewer.events.CLICKED, startZoom);
                        viewer.on(viewer.events.CLICKED, startZoom);
                    }
                }
                if (thumbnails && thumbnails.initialized) {
                    thumbnails.render();
                }
                showActionButtons();
                handleViewerReady();
            });
        }
        function onImageLoaded() {
            self.trigger(self.events.IMG_LOADED);
        }
        function onFeaturesChangedOrNoWarning() {
            if (zoom.initialized && !zoom.disabled) {
                zoom.disable();
            }
            if (focusOnFeatureChange && el) {
                dom.scrollTo(el);
            }
            if (arModal !== null && arModal.hide) {
                arModal.hide(true);
            }
        }
        function onFeaturesChanged() {
            if (ARQuickLookBtn) {
                ARQuickLookBtn.tryToShow();
            }
        }

        function onCurrentChange(evt, data) {
            if (thumbnails && thumbnails.initialized) {
                thumbnails.setActive(data.current);
            }
        }
        function onFirstFrameComplete(evt, data) {
            self.trigger(self.events.FIRST_FRAME_COMPLETE, data);
        }
        function onDownloadComplete(evt, data) {
            self.trigger(self.events.DOWNLOAD_COMPLETE, data);
        }
        function onFeaturesCanceled(evt, data) {
            self.trigger(self.events.FEATURES_CANCELED, data);
        }
        function onAltContentLoaded(evt, data) {
            tryToRemoveLoader(evt, data);
        }
        function onFrameActivatedTryToShowLoading(evt, data) {
            var active = list360 ? list360.querySelector('li.active') : null;
            var index = data && data.index ? data.index : null;
            if (isStackedAndNotFS()) {
                return;
            }
            if (active &&
                active.classList &&
                active.classList.contains('hidden') &&
                (isCarousel() || framesHandler.isAltId(index))) {
                showLoading(0, null);
            }
            else {
                hideLoading(0, null);
            }
        }
        function tryToRemoveLoader(evt, data) {
            var active = list360 ? list360.querySelector('li.active') : null;
            var currentIndex;
            var loadedIndex = data && data.secuence ? data.secuence : '';
            if (active && data && data.secuence) {
                currentIndex = active.dataset ? active.dataset.index : active.getAttribute('data-index');
                currentIndex = '' + currentIndex;
                loadedIndex = '' + loadedIndex;
                if (currentIndex === loadedIndex) {
                    if (viewer && viewer.initialized) {
                        viewer.handleTooltip(active, currentIndex, currentIndex);
                        viewer.handleZoomButton(active, currentIndex, currentIndex);
                        viewer.handleActionBtns(active, index, isCustomImage);
                    }
                    hideLoading(0, null);
                }
            }
        }
        function initThumbnails() {
            var opts = {
                model: model,
                el: thumbBarEl,
                url: currentFeatures ? currentFeatures.paths : null,
                tags: tags,
                dom: dom,
                framesHandler: framesHandler,
                ariaLabel: ariaLabel,
            };
            if (!thumbnails.initialized) {
                thumbnails = thumbnails.create(opts);
                thumbnails.on(thumbnails.events.CLICK, onThumbClick);
                thumbnails.on(thumbnails.events.READY, onThumbsReady);
                thumbnails.on(thumbnails.events.CONTENT_TYPE_COMPLETE, setContentDownloaded);
                el.classList.add('has-thumbs');
            }
        }
        function onThumbsReady() {
            var viewerContainerRect = viewerContainer ? viewerContainer.getBoundingClientRect() : null;
            var thumbBarParentEl;
            if (thumbBarEl && viewerContainerRect && areVerticalThumbs()) {
                thumbBarParentEl = thumbBarEl.parentElement || null;
                if (thumbBarParentEl) {
                    if (thumbBarParentEl.classList &&
                        thumbBarParentEl.classList.contains('has-scroll')) {
                        thumbBarEl.style.height = (viewerContainerRect.height - 60) + 'px';
                    }
                    else {
                        thumbBarEl.style.height = viewerContainerRect.height + 'px';
                    }
                }
            }
            if (thumbnails && thumbnails.isHidden) {
                if (zoom && zoom.disabled === false) {
                    logger.log('Can not show thumbnails because zoom is currently enabled.');
                }
                else {
                    thumbnails.show(true);
                }
            }
            updateViewport();
            self.trigger(self.events.THUMBS_READY);
        }
        function onThumbClick(evt, data) {
            var imageViewed = true;

                        if (viewer && viewer.initialized) {
                tryToDisableZoom();
                if (viewer.customImageOn) {
                    hideImage();
                }
                viewer.goToIndex(data.index);
            }
            if (data.alternateIndex) {
                self.trigger(self.events.ALT_THUMBS_CLICKED, { index: data.alternateIndex, contentType: data.contentType });
                sendAltContentTag(evt, data, imageViewed);
            }
            else {
                self.trigger(self.events.THUMB_CLICKED, { index: data.index });
            }
            viewer.addMouseCallbacks();
            setStateWithActive();
        }
        function preventEvents() {
            preventAllEvents.status = true;
            setTimeout(function () {
                preventAllEvents.status = false;
            }.bind(self), 300);
        }
        function toggleZoom(evt, data) {
            if (evt) {
                if (typeof evt === 'string' &&
                    (evt === zoomBtn.events.CLICK || evt === zoomBtn.events.TOUCHEND)) {
                    evt = data;
                }
                evt.preventDefault();
                evt.stopPropagation();
                preventEvents();
                if (evt.target && evt.target.classList.contains('cylindo-icon-zoom-on')) {
                    clickTypeZoom = 3;
                }
            }
            if (zoom.initialized) {
                if (evt &&
                    isStacked() &&
                    fullScreenBtn.isEnabled()) {
                    if (zoom.disabled) {
                        toggleStackedZoom(null, {
                            frame: null,
                            btnZoom: zoomBtn.btn,
                            btnZoom2: zoomBtn2.btn,
                            withoutCoords: true
                        });
                    }
                    else {
                        zoom.disable();
                    }
                }
                else {
                    if (zoom.disabled) {
                        startZoom();
                    }
                    else {
                        zoom.disable();
                    }
                }
            }
            if (customImgZoom && !isStackedAndNotFS()) {
                viewer.removeMouseCallbacks();
            }
        }
        function tryToDisableZoom() {
            try {
                if (!zoom.disabled) {
                    zoom.disable();
                }
            }
            catch (ex) {
                logger.error('Can not disable zoom.', ex);
            }
            finally {
                if (!viewer.enabled) {
                    viewer.enable();
                }
            }
        }
        function processFullScreenEvent(evt, data) {
            if (arModal !== null && arModal.hide) {
                arModal.hide(true);
            }
            fullScreenBtn.processFullScreenEvent();
            if (document[fullScreenBtn.fullscreenElement]) {
                enterFullScreen();
            }
            else {
                exitFullScreen();
            }
        }
        function enterFullScreen() {
            self.trigger(self.events.ENTER_FULLSCREEN);
            tags.send(tags.events.FULLSCREEN);
            setFullScreenInOutState();
        }
        function exitFullScreen() {
            var zoomWasOpened = false;
            if (!zoom.disabled) {
                zoomWasOpened = true;
                zoom.on(zoom.events.DISABLED, doExitFullScreen);
                toggleZoom();
            }
            else {
                doExitFullScreen();
            }
            function doExitFullScreen() {
                if (zoomWasOpened) {
                    zoom.off(zoom.events.DISABLED, doExitFullScreen);
                }
                modifyStackedBehivor(false);
                fullScreenBtn.off(fullScreenBtn.events.FULLSCREEN_CHANGED, processFullScreenEvent);
                fullScreenBtn.cancelSubscriptionToFullScreenChangeEvt();
                self.trigger(self.events.EXIT_FULLSCREEN);
                fullScreenBtn.setStatus(false);
                setFullScreenInOutState();
            }
        }
        function toggleFullScreen(evt) {
            var index = null;
            var zoomWasOpened = false;
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                preventEvents();
            }
            if (!zoom.disabled) {
                zoomWasOpened = true;
                zoom.on(zoom.events.DISABLED, doToggleFullScreen);
                toggleZoom();
            }
            else {
                doToggleFullScreen();
            }
            function doToggleFullScreen() {
                if (zoomWasOpened) {
                    zoom.off(zoom.events.DISABLED, doToggleFullScreen);
                }
                if (isStacked()) {
                    if (evt && evt.target) {
                        fullScreenBtn.setBtn(evt.target);
                        index = fullScreenBtn.btn.getAttribute('data-index');
                    }
                }
                fullScreenBtn.toggleFullScreen();
                fullScreenBtn.off(fullScreenBtn.events.FULLSCREEN_CHANGED, processFullScreenEvent);
                fullScreenBtn.on(fullScreenBtn.events.FULLSCREEN_CHANGED, processFullScreenEvent);
                if (customImgZoom) {
                    if (!isStackedAndNotFS()) {
                        viewer.removeMouseCallbacks();
                    }
                    setTimeout(function () {
                        if (zoom && !zoom.disabled) {
                            zoom.disable();
                        }
                    }, 100);
                }
                fullScreenBtn.setStatus(!fullScreenBtn.isEnabled());
                if (isStacked()) {
                    if (fullScreenBtn.isEnabled()) {
                        modifyStackedBehivor(fullScreenBtn.isEnabled());
                    }
                    if (index && index !== customImageClass) {
                        viewer.goToIndex(index);
                    }
                }

            }
        }
        function showActionButtons() {
            if (!hideActionBtns) {
                dom.fadeIn(actionButtons);
                if (fullScreenBtn.isAvailable()) {
                    fullScreenBtn.show();
                    subscribeToFullScreenEvents();
                }
                if (zoomBtn.isAvailable()) {
                    zoomBtn.show();
                    subscribeToZoomBtnEvents();
                }
            }
        }
        function hideActionButtons() {
            dom.fadeOut(actionButtons, 0);
            removeAllZoomBtnEvents();
            removeAllFullScreenEvents();
        }
        function initZoomBtn() {
            var isMobile = util.browser.isMobile();
            zoomBtn = ZoomBtn.create({
                model: model,
                viewerContainer: viewerContainer,
                btn: getButton('.threesixty-button'),
                isMobile: isMobile,
                ariaLabel: ariaLabel,
            });
            zoomBtn2 = ZoomBtn.create({
                model: model,
                viewerContainer: viewerContainer,
                btn: getButton('.threesixty-button-secondary'),
                isMobile: isMobile,
                ariaLabel: ariaLabel,
            });
        }
        function cancelEventPropagation(evt, data) {
            if (typeof evt === 'string' &&
                typeof data === 'object') {
                evt = data;
                evt.preventDefault();
                evt.stopPropagation();
            }
            else if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        }
        function initFullscreenBtn() {
            fullScreenBtn = fullScreenBtn.create({
                model: model,
                viewerContainer: viewerContainer,
                btn: getButton('.fullscreen-button'),
                ariaLabel: ariaLabel,
            });
        }
        function subscribeToFullScreenEvents(btn) {
            btn = btn || fullScreenBtn.btn;
            if (btn) {
                removeCustomFullScreenEvents(btn);
                btn.addEventListener('click', toggleFullScreen);
                btn.addEventListener('touchstart', cancelEventPropagation);
                btn.addEventListener('touchend', toggleFullScreen);
            }
        }
        function removeAllFullScreenEvents(btn) {
            btn = btn || fullScreenBtn.btn;
            if (btn) {
                removeCustomFullScreenEvents(btn);
            }
        }
        function removeCustomFullScreenEvents(btn) {
            if (btn) {
                btn.removeEventListener('click', toggleFullScreen);
                btn.removeEventListener('touchstart', cancelEventPropagation);
                btn.removeEventListener('touchend', toggleFullScreen);
            }
        }
        function subscribeToZoomBtnEvents(btn) {
            if (zoomBtn) {
                zoomBtn.addEventsToBtn(btn);
                removeZoomBtnSubscriptions();
                zoomBtn.on(zoomBtn.events.CLICK, toggleZoom);
                zoomBtn.on(zoomBtn.events.TOUCHSTART, cancelEventPropagation);
                zoomBtn.on(zoomBtn.events.TOUCHEND, toggleZoom);
            }
        }
        function removeAllZoomBtnEvents() {
            if (zoomBtn) {
                zoomBtn.removeEventsToBtn();
                removeZoomBtnSubscriptions();
            }
        }
        function removeZoomBtnSubscriptions() {
            zoomBtn.off(zoomBtn.events.CLICK, toggleZoom);
            zoomBtn.off(zoomBtn.events.TOUCHSTART, cancelEventPropagation);
            zoomBtn.off(zoomBtn.events.TOUCHEND, toggleZoom);
        }
        /**
         * Destroy and eliminates all configuration of actual viewer instance
         */
        function destroy() {
            var viewerContainer = el ? el.querySelector('.cylindo-viewer-container') : null;
            if (viewerContainer) {
                viewerContainer.removeEventListener('touchstart', onTouchStart);
                viewerContainer.removeEventListener('touchend', onTouchEnd);
            }
            hideActionButtons();
            pubsub.destroy();
            if (viewer && viewer.initialized) {
                viewer.destroy();
            }
            if (thumbnails && thumbnails.initialized) {
                thumbnails.destroy();
            }
            if (fullScreenBtn && fullScreenBtn.initialized) {
                fullScreenBtn.destroy();
            }
            if (ARQuickLookBtn && ARQuickLookBtn.initialized) {
                ARQuickLookBtn.destroy();
            }
            if (zoomBtn && zoomBtn.initialized) {
                zoomBtn.destroy();
            }
            if (zoomBtn2 && zoomBtn2.initialized) {
                zoomBtn2.destroy();
            }
            if (zoom && zoom.initialized) {
                zoom.destroy();
            }
            if (arModal && arModal.initialized) {
                arModal.destroy();
            }
            viewer = null;
            thumbnails = null;
            if (el) {
                el.innerHTML = '';
            }
            loaderEl = null;
            placeholder = null;
            viewerContainer = null;
            list360 = null;
            el = null;
            window.removeEventListener('resize', updateViewport);
        }
        /**
         * This function is used to show a custom image at runtime via the instace (controller).
         * @param {string} url The URL of the image to show.
         * @param {string} large (optional) The URL for the image to be used for zooming.
         * @throws {Error} Error if the URL param is not specified.
         */
        function showImage(url, large) {
            var tooltipAltImgText = model.get('tooltipAltImgText');
            if (!url) {
                throw new Error('There must be a URL specified for the custom image.');
            }
            if (viewer &&
                viewer.initialized &&
                viewerReady) {

                if (typeof tooltipAltImgText === 'string' &&
                    tooltipAltImgText.length > 0) {
                    viewer.showTooltip(model.get('tooltipAltImgText'));
                }
                else {
                    viewer.hideTooltip();
                }
                if (large && typeof large === 'string' && large.length > 0 &&
                    model.get('alternateContentZoom') === true) {
                    customImgZoom = true;
                }
                else {
                    customImgZoom = false;
                    large = null;
                }
                tryToDisableZoom();
                customImgFrame = viewer.showImage.call(viewer, url, large);
                if (!isStacked() ||
                    (isStacked() &&
                        fullScreenBtn &&
                        fullScreenBtn.initialized &&
                        fullScreenBtn.isEnabled())) {
                    showLoading(0);
                }
                addCustomImgEvents();
                if (!isStackedAndNotFS()) {
                    viewer.removeMouseCallbacks();
                }
            }
            else {
                throw new Error('Viewer is not initialized or ready');
            }
        }
        function addCustomImgEvents() {
            var element = customImgFrame ? customImgFrame : viewerContainer;
            element.addEventListener('mousedown', customImgDown);
            element.addEventListener('mouseup', customImgUp);
            if (element !== viewerContainer) {
                element.addEventListener('touchstart', onTouchStart);
                element.addEventListener('touchend', onTouchEnd);
            }
        }
        function removeCustomImgEvents() {
            var element = customImgFrame ? customImgFrame : viewerContainer;
            element.removeEventListener('mousedown', customImgDown);
            element.removeEventListener('mouseup', customImgUp);
            if (element !== viewerContainer) {
                element.removeEventListener('touchstart', onTouchStart);
                element.removeEventListener('touchend', onTouchEnd);
            }
        }
        function customImgDown(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            customImgClick.x = evt.clientX;
            customImgClick.y = evt.clientY;
        }
        function customImgUp(evt) {
            var frameDetails, mouseCoords, frame, btnZoom, btnZoom2, target;
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                target = evt.target;
            }
            if (evt &&
                evt.target &&
                evt.target.classList.contains('fullscreen-button')) {
                return;
            }
            if (Math.abs(evt.clientX - customImgClick.x) > 10 ||
                Math.abs(evt.clientY - customImgClick.y) > 10) {
                return;
            }
            mouseCoords = {
                currentX: customImgClick.x,
                currentY: customImgClick.y
            };
            if (zoom &&
                zoom.initialized &&
                customImgZoom) {
                removeCustomImgEvents();
            }
            if (isStacked()) {
                frame = target.tagName === 'IMG' ?
                    target.parentElement :
                    target.tagName === 'LI' ?
                        target : null;
                btnZoom = frame ?
                    frame.querySelector('.cylindo-action-button-group .threesixty-button') : null;
                btnZoom2 = frame ?
                    frame.querySelector('.cylindo-action-button-group .threesixty-button-secondary') : null;
                frameDetails = {
                    frame: frame,
                    btnZoom: btnZoom,
                    btnZoom2: btnZoom2
                };
                if (isStackedAndNotFS()) {
                    util.object.extend(mouseCoords, frameDetails);
                }
                toggleStackedZoom(viewer.events.CUSTOM_IMG_CLICKED, mouseCoords);
            }
            else {
                startZoom(viewer.events.CUSTOM_IMG_CLICKED, mouseCoords);
            }
        }
        /**
         * This function is used to go back to the current image before showImage function
         * is called via the instace (controller).
         * @method hideImage
         */
        function hideImage() {
            if (viewer && viewer.initialized) {
                tryToDisableZoom();
                removeCustomImgEvents();
                customImgZoom = false;
                if (!isStackedAndNotFS()) {
                    viewer.addMouseCallbacks();
                }
                hideLoading(0);
                viewer.hideImage();
            }
        }
        this.printGrid = function () {
            if (zoom.initialized) {
                zoom.printGrid();
            }
        };
        function toggleRotation() {
            inRotation = !inRotation;
            return inRotation;
        }
        /**
         *  Return if mouse event is rotating image
         * */
        function isRotating() {
            return inRotation;
        }
        /**
         * Return if mouse event when paning on zoomed image
         */
        function isPanning() {
            return zoom.isPanning;
        }

        /**
         * Gets threesixty instance maximum size
         */
        function getThreesixtyMaxSize() {
            var list360Rect = list360.getBoundingClientRect();
            var width = list360Rect.width;
            var height = list360Rect.height;
            width = Math.round(width);
            height = Math.round(height);
            return Math.max(height, width);
        }
        /**
         * returns current index for threesixty if no viewer instance created
         * returns 1 (error code)
         */
        function getCurrentThreesixtyIndex() {
            if (viewer) {
                return viewer.getCurrentThreesixtyIndex();
            }
            return 1;
        }
        /**
         * Callback function which executes when close viewer zoom 
         */
        function exitZoom() {
            if (zoom && !zoom.disabled) {
                zoom.disable();
                return true;
            }
            return false;
        }
        function rotate(degrees, milliseconds) {
            if (typeof degrees === 'number' &&
                (typeof milliseconds === 'number' || typeof milliseconds === 'undefined')) {
                if (viewer &&
                    viewer.initialized &&
                    typeof viewer.autoRotate === 'function') {
                    viewer.autoRotate(degrees, milliseconds);
                }
                else {
                    logger.warning('Viewer instance is not initialized or it may not support autoRotate function');
                }
            }
            else {
                logger.warning('You must pass the degrees and milliseconds value as an integer to rotate the viewer.', angle, milliseconds);
            }
        }
        function goToAngle(angle) {
            if (typeof angle === 'number') {
                if (viewer &&
                    viewer.initialized &&
                    typeof viewer.goToAngle === 'function') {
                    viewer.goToAngle(angle);
                }
                else {
                    logger.warning('Viewer instance is not initialized or it may not support goToAngle function');
                }
            }
            else {
                logger.warning('You must pass the angle value as an integer to rotate the viewer.', angle);
            }
        }
        function previousSlide() {
            var rightDirection = false;
            if (viewer &&
                viewer.initialized &&
                typeof viewer.autoSlideCarousel === 'function') {
                viewer.autoSlideCarousel(rightDirection);
            }
            else {
                logger.warning('Viewer instance is not initialized or it may not support previousSlide function');
            }
        }
        function nextSlide() {
            var rightDirection = true;
            if (viewer &&
                viewer.initialized &&
                typeof viewer.autoSlideCarousel === 'function') {
                viewer.autoSlideCarousel(rightDirection);
            }
            else {
                logger.warning('Viewer instance is not initialized or it may not support nextSlide function');
            }
        }

        function autoZoom(x, y, index) {
            var frame = isStackedAndNotFS() && index ?
                list360.querySelector('li.cylindo-viewer-frame-' + index) : null;
            var zoomWasOpened = false;
            var data = frame ? { frame: frame } : null;
            var btnZoom = frame ? frame.querySelector('.cylindo-action-button-group .threesixty-button') : getButton('.threesixty-button');
            var btnZoom2 = frame ? frame.querySelector('.cylindo-action-button-group .threesixty-button-secondary') : getButton('.threesixty-button-secondary');
            if (zoom && zoom.initialized) {
                if (!zoom.disabled) {
                    if (index &&
                        String(index) !== String(zoom.getFrameIndex())) {
                        zoomWasOpened = true;
                        zoom.on(zoom.events.DISABLED, doAutoZoom);
                        zoom.disable();
                    }
                    else {
                        moveZoom();
                    }
                }
                else {
                    doAutoZoom();
                }
            }
            function doAutoZoom() {
                if (zoomWasOpened) {
                    zoom.off(zoom.events.DISABLED, doAutoZoom);
                }
                if (zoomBtn.btn !== btnZoom) {
                    zoomBtn.setBtn(btnZoom);
                }
                if (zoomBtn2.btn !== btnZoom2) {
                    zoomBtn2.setBtn(btnZoom2);
                }
                if (isStackedAndNotFS()) {
                    if (frame) {
                        disableViewer();
                        zoom.autoZoom(x, y, fullScreenBtn.isEnabled(), frame);
                    }
                    else {
                        logger.error('Zoom function needs a valid index parameter to open zoom for this type of viewer.');
                    }
                }
                else {
                    disableViewer();
                    zoom.autoZoom(x, y, fullScreenBtn.isEnabled());
                }
                handleZoomButton(data);
            }
            function moveZoom() {
                zoom.moveAutoZoom(x, y);
            }
        }
        function isCarousel() {
            return viewerDetails && viewerDetails.presentation === presentations.CAROUSEL.NAME;
        }
        function isStacked() {
            return viewerDetails && viewerDetails.presentation === presentations.STACKED.NAME;
        }
        function isStackedAndNotFS() {
            return isStacked() && !fullScreenBtn.isEnabled();
        }
        function setState(state) {
            if (state &&
                state !== self.currentState) {
                logger.log('State changed from: ' + self.currentState + ' to: ' + state);
                self.currentState = state;
            }
            else {
                if (!state) {
                    logger.error('State cannot be modified.');
                }
            }
        }
        function getState() {
            return self.currentState;
        }
        function getStatesObject(index, li) {
            var statesObj;
            if (li || index) {
                if (li && li.classList &&
                    (li.classList.contains('cylindo-img-not-found') ||
                        li.classList.contains('cylindo-video-not-found'))) {
                    statesObj = viewerStates.FALLBACK;
                }
                else if (li &&
                    li.classList.contains(customImageClass)) {
                    statesObj = viewerStates.CUSTOM;
                }
                else {
                    if (index) {
                        if (framesHandler.is360Frame(index)) {
                            statesObj = viewerStates.FRAME;
                        }
                        else if (framesHandler.isAltId(index)) {
                            statesObj = viewerStates.ALT_CONT;
                        }
                    }
                }
            }
            return statesObj;
        }
        function setStateWithActive() {
            var active, statesObj;
            active = getActiveDetails();
            statesObj = getStatesObject(active.index, active.li);
            if (statesObj) {
                setState(statesObj.FRAME);
            }
        }
        function setZoomInOutState(index, li, enabled) {
            var state;
            var statesObj = getStatesObject(index, li);
            if (statesObj) {
                if (enabled) {
                    state = fullScreenBtn.isEnabled() ?
                        statesObj.ZOOM_FULLSCREEN :
                        statesObj.ZOOM;
                }
                else {
                    state = fullScreenBtn.isEnabled() ?
                        statesObj.FULLSCREEN :
                        statesObj.FRAME;
                }
            }
            setState(state);
        }
        function setFullScreenInOutState() {
            var active = getActiveDetails();
            var statesObj = getStatesObject(active.index, active.li);
            var state;
            if (statesObj) {
                state = fullScreenBtn.isEnabled() ? statesObj.FULLSCREEN : statesObj.FRAME;
            }
            setState(state);
        }
        function getActiveDetails() {
            var active = list360 ? list360.querySelector('li.active') : null;
            var li = active ? active : null;
            var index;
            if (li) {
                index = li.dataset ? li.dataset.index : li.getAttribute('data-index');
            }
            return {
                li: li,
                index: index
            };
        }

        function areVerticalThumbs() {
            return thumbLocation === thumbLocations.LEFT || thumbLocation === thumbLocations.RIGHT;
        }
        function actionBtnsHandleHiddenClass(shouldAddClass, actionBtnsClass) {
            if (actionButtons && actionButtons.classList) {
                if (shouldAddClass) {
                    actionButtons.classList.add(actionBtnsClass);
                }
                else {
                    actionButtons.classList.remove(actionBtnsClass);
                }
            }
        }
        function getButton(query) {
            return actionButtons ? actionButtons.querySelector(query) : null;
        }
        function forwardFirstFeaturesErrorEvent(set) {
            self.trigger(self.events.FEATURES_ERROR, {
                features: set instanceof Array ? set : null,
            });
        }
        function forwardFeaturesErrorEvent() {
            var features = model ? model.get('features') : [];
            self.trigger(self.events.FEATURES_ERROR, {
                features: features,
            });
        }
        function handleViewerReady() {
            logger.event(viewerDetails.presentation.toUpperCase() + ' viewer ready');
            viewerReady = true;
            self.trigger(self.events.VIEWER_READY);
        }
        function triggerSeenTag() {
            if (!tryToTriggerSeenTag()) {
                if (seenEventsAttached) {
                    removeSeenEvents();
                }
                if (window.addEventListener) {
                    window.addEventListener('scroll', tryToTriggerSeenTag, false);
                    window.addEventListener('resize', tryToTriggerSeenTag, false);
                    seenEventsAttached = true;
                }
                else if (window.attachEvent) {
                    window.attachEvent('onscroll', tryToTriggerSeenTag);
                    window.attachEvent('onresize', tryToTriggerSeenTag);
                    seenEventsAttached = true;
                }
            }
        }
        function tryToTriggerSeenTag() {
            if (lastTryToTriggerSeenCall && (Date.now() - lastTryToTriggerSeenCall) < 250) {
                return;
            }
            lastTryToTriggerSeenCall = Date.now();
            if (!viewerSeen && isContainerInViewport()) {
                viewerSeen = true;
                tags.send(tags.events.SEEN);
                if (seenEventsAttached) {
                    removeSeenEvents();
                }
                return true;
            }
            return false;
        }
        function removeSeenEvents() {
            if (window.removeEventListener) {
                window.removeEventListener('scroll', tryToTriggerSeenTag, false);
                window.removeEventListener('resize', tryToTriggerSeenTag, false);
                seenEventsAttached = false;
            }
            else if (window.attachEvent) {
                window.detachEvent('onscroll', tryToTriggerSeenTag);
                window.detachEvent('onresize', tryToTriggerSeenTag);
                seenEventsAttached = false;
            }
        }
        function isContainerInViewport() {
            var windowHeight, windowWidth, vertInView, horInView;
            var rect = viewerContainer && viewerContainer.getBoundingClientRect();
            if (!rect) {
                return false;
            }
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);
            windowWidth = (window.innerWidth || document.documentElement.clientWidth);
            vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
            horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
            return (vertInView && horInView);
        }
        function sendAltContentTag(evt, data, imageViewed) {
            var domains = /(cylindev.com|cylindo.com)/ig;
            var image = data && data.image ? network.resolveProtocol(data.image) : '';
            var isCylindoImage = image && domains.test(image) || false;
            var cylindoURL = isCylindoImage ? new URL(image) : null;
            var cylindoPath = cylindoURL && cylindoURL.pathname ? cylindoURL.pathname.split('/') : null;
            var productCode = '';
            var frameNumber = '';
            var imageName = '';
            var tmpFeatures = [];
            var features = [];
            var event = imageViewed ? tags.events.ALT_IMAGE_VIEWED : tags.events.ALT_IMAGE_LOADED;
            var i;
            var feature;
            var version;
            if (cylindoPath && cylindoPath.length > 0) {
                productCode = cylindoPath[5];
                frameNumber = cylindoPath[7];
                imageName = cylindoPath[8];
                tmpFeatures = cylindoURL.searchParams.getAll("feature");
                version = cylindoURL.searchParams.get("version");
            }
            for (i = 0; i < tmpFeatures.length; i++) {
                feature = tmpFeatures[i].split(':');
                if (feature.length === 2) {
                    features.push(tmpFeatures[i].split(':')[0]);
                    features.push(tmpFeatures[i].split(':')[1]);    
                }
            }
            tags.send(event, {
                image: image || '',
                isCylindoImage: isCylindoImage,
                frameNumber: frameNumber,
                productCode: productCode,
                imageName: imageName,
                features: features,
                version: version,
            });
        }
    };

    View.counter = 0;

    View.prototype.events = {
        READY: 'view:ready',
        ZOOM: 'view:zoom',
        ZOOM_ENTER: 'view:zoom:enter',
        ZOOM_EXIT: 'view:zoom:exit',
        VIEWER_READY: 'view:threesixty:ready',
        DOWNLOAD_COMPLETE: 'view:download:complete',
        THUMBS_READY: 'view:thumbs:ready',
        THUMB_CLICKED: 'view:thumbs:clicked',
        ALT_THUMBS_CLICKED: 'view:alt:thumbs:clicked',
        FIRST_FRAME_COMPLETE: 'view:firstframe:complete',
        IMG_LOADED: 'view:image:loaded',
        ENTER_FULLSCREEN: 'view:fullscreen:enter',
        EXIT_FULLSCREEN: 'view:fullscreen:exit',
        START_SPIN: 'view:spin:start',
        END_SPIN: 'view:spin:end',
        PREV: 'view:prev',
        NEXT: 'view:next',
        FEATURES_CANCELED: 'view:features:canceled',
        FEATURES_ERROR: 'view:features:error',
        AR_BUTTON_READY: 'view:ar:button:ready',
        AR_BUTTON_ERROR: 'view:ar:button:error'
    };

    var publicAPI = {
        create: function (model, tags, framesHandler) {
            return new View(model, tags, framesHandler);
        }
    };

    window.cylindo.addModule('view', publicAPI);
}).call(this);
