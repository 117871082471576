(function () {
    'use strict';

    var Network = function () {
        var xhrs = {};
        var reqcount = 0;
        var self = this;
        var util = cylindo.getModule('cylindo.core.util');

        var defaultsettings = {
            method: 'GET',
            contentType: 'text/plain',
            traditional: true,
            data: {}
        };
        var settings = cylindo.getModule('cylindo.core.config');
        var metadata = settings.get('metadata');
        this.addQueryString = addQueryString;
        function handleRequestError(xhr, textStatus, callback) {
            var status = xhr && xhr.status ? xhr.status : null;
            console.error('Request failed: %s - %s', status, textStatus);
            if (callback) {
                try {
                    callback(null, xhr);
                }
                catch (ex) { }
            }
        }
        function prepareOpts(opts, xServiceVersion) {
            opts = util.object.extend(true, {}, defaultsettings, opts);
            if (xServiceVersion) {
                opts.data['x-service-version'] = xServiceVersion;
            }
            if (opts.type == 'POST') {
                opts.data = JSON.stringify(opts.data);
            }
            opts.method = opts.type ? opts.type : 'GET';
            return opts;
        }
        function request(url, callback, opts, errCb, responseType) {
            var id;
            var xhr;
            var queryString = '';
            var method = opts.method;
            var useOnload = false;
            responseType = responseType || null;
            id = ++reqcount;
            if (window.XDomainRequest) {
                useOnload = true;
                xhr = new XDomainRequest();
            }
            else if (window.XMLHttpRequest) {
                xhr = new XMLHttpRequest();
            }
            xhrs[id] = xhr;
            if (useOnload) {
                xhr.onload = function () {
                    handleSuccess(xhr, callback);
                };
                xhr.onerror = function () {
                    handleError(xhr, callback, errCb);
                };
            }
            else {
                xhr.onreadystatechange = function handleXhrState() {
                    if (xhr.readyState === 4 || (method === 'HEAD' && xhr.readyState === xhr.DONE)) {
                        if (xhr.status === 200) {
                            handleSuccess(xhr, callback);
                        }
                        else {
                            handleError(xhr, callback, errCb);
                        }
                    }
                };
            }
            if (method === 'POST') {
                xhr.open(method, url, true);
                if ((!util.browser.isMobile() &&
                    util.browser.isSafari() &&
                    parseFloat(util.browser.browser.version, 10) < 9) ||
                    (util.browser.isIOS() && parseFloat(util.browser.getIOSVersion(), 10) < 9)) {
                    xhr.setRequestHeader('Content-Type', 'application/json');
                }
                xhr.send(opts.data);
            }
            else {
                url = addQueryString(url, opts.data);
                xhr.open(method, url, true);
                if (responseType) {
                    xhr.responseType = responseType;
                }
                xhr.send();
            }
            function handleSuccess(xhr, callback) {
                var index;
                var responsedata = xhr.response || xhr.responseText || '';
                try {
                    responsedata = JSON.parse(responsedata);
                }
                catch (e) {
                }
                if (typeof responsedata === 'string' && responsedata.indexOf('callback') > -1) {
                    responsedata = responsedata.replace('callback(', '');
                    index = responsedata.lastIndexOf(')');
                    if (index > 0) {
                        responsedata = responsedata.substring(0, index);
                    }
                    responsedata = JSON.parse(responsedata);
                }
                if (callback) {
                    callback(responsedata, xhr);
                }
            }
            function handleError(xhr, callback, errCb) {
                var statusText = xhr && xhr.statusText ? xhr.statusText : null;
                if (errCb) {
                    errCb(xhr, xhr.statusText, callback);
                }
                else {
                    handleRequestError(xhr, statusText, callback);
                }
            }
            return id;
        }
        /**
         *  Public function for requests made from viewer instance
         * @param {string} url url string 
         * @param {function} callback function which should be executed after request
         * @param {array} opts options sets as array
         * @param {string} errCb 
        */
        this.doRequest = function (url, callback, opts, errCb, responseType) {
            opts = prepareOpts(opts, null);
            return request(url, callback, opts, errCb, responseType);
        };
        this.abort = function (requestID) {
            if (xhrs[requestID]) {
                xhrs[requestID].abort();
                delete xhrs[requestID];
            }
        };
        this.resolveProtocol = function (url) {
            if (url) {
                if (url.indexOf('data:image') !== -1) {
                    return url;
                }
                else if (/^(https?:)?\/\/([a-zA-Z\d-]+\.){0,}cylindo\.com/.test(url) &&
                    !util.browser.equalOrLessThanIE10() &&
                    !(util.browser.isSafari() && parseFloat(util.browser.browser.version, 10) < 7)) {
                    return 'https:' + url.replace('file:', '').replace('http:', '').replace('https:', '');
                }
                else if (url.indexOf('https:') === -1 &&
                    url.indexOf('http:') === -1 &&
                    url.indexOf('file:') === -1) {
                    if (window.location.protocol === 'https:') {
                        return 'https:' + url.replace('file:', '').replace('http:', '').replace('https:', '');
                    }
                    else {
                        return 'http:' + url.replace('file:', '').replace('http:', '').replace('https:', '');
                    }

                }
            }
            return url;
        };
        this.requestImage = function (url, callback, onError, ignoreCache, authorizationKey) {
            var xhr;
            if (ignoreCache) {
                url = url.indexOf('?') > -1 ? url += '&' : url += '?';
                url += ('_=' + Date.now() + '&directives=IGNORE-CACHE');
            }
            xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        if (callback) {
                            callback(xhr.response, xhr);
                        }
                    } else {
                        handleRequestError(xhr, xhr.statusText, onError);
                    }
                }
            };

            xhr.open('GET', url, true);
            if (authorizationKey) {
                xhr.setRequestHeader('Authorization', 'Basic ' + authorizationKey);
            }
            xhr.responseType = 'blob';
            xhr.send();
        };
        this.getXhrById = function (id) {
            return xhrs[id];
        };


        function addQueryString(url, params) {
            var queryString;
            if (params) {
                queryString = serialize(params);
                if (queryString.length) {
                    if (url.indexOf('?') !== -1) {
                        url = (url.slice(-1) === '&' || url.slice(-1) === '?') ? url + queryString : url + '&' + queryString;
                    }
                    else {
                        url = url + '?' + queryString;
                    }
                }
            }
            return url;
        }
        function serialize(obj) {
            var str = [];
            for (var p in obj) {
                if (obj.hasOwnProperty(p)) {
                    if (obj[p] instanceof Array) {
                        obj[p].forEach(pushArrayElement);
                    }
                    else {
                        str.push(p + '=' + obj[p]);
                    }
                }
            }
            return str.join('&');
            function pushArrayElement(aVal) {
                str.push(p + '=' + aVal);
            }
        }

    };
    window.cylindo.addModule('cylindo.core.network', new Network());
}).call(this);
