(function () {
    var Zoom = function (config) {
        var self = this;

        var util = cylindo.getModule('cylindo.core.util');
        var dom = config.dom;
        var pubsub = cylindo.getModule('cylindo.util.pubsub').create();
        var network = cylindo.getModule('cylindo.core.network');
        var iosBrowser80 = util.browser.browser.ios && util.browser.browser.version === '8.0';
        var easingHelper = cylindo.getModule('cylindo.helpers.easing');

        var model = config.model;
        var logger = model.getLogger();
        var tags = config.tags;

        var cylindoZoomImagesLoaded = [];
        var customZoom = model.get('customZoomContainer');
        var zoomBackgroundColor;
        var previousBackgroundColor;
        var container = config.el;
        var viewPort = container.parentNode;
        var viewerContainer = config.el;
        var isCustomZoom = false;
        var containerRect = viewerContainer.getBoundingClientRect();
        var customContainerRect = null; 
        var useCustomContainer = false;
        var processZoomTimestamp = 0;
        var secondTouchDelta = null; 
        var thumbs = model.get('thumbs');
        var zoomOverlapsThumbs = model.get('zoomOverlapsThumbs');

        var viewerList = config.list;

        var img = null;
        var grid = null;
        var imgRect = null;
        var topOffset = null;
        var leftOffset = null;
        var gridRectangle = null;
        var largeStaticImage = null;
        var currentImgElement = null;
        var previousImgElement = null;
        var customZoomForced = false;
        var customZoomID = 'cylindo-custom-zoom-forced';

        var gridFont = 'Arial';

        var mainZoomElClass = 'cylindo-zoom-imagery';
        var backgroundLayerClass = 'cylindo-zoom-background';
        var zoomImgLayerClass = 'cylindo-zoom-images';
        var zoomGridClass = 'cylindo-zoom-grid-';

        var drawImageBackground = model.get('drawImageBackground') || true;

        var gridSize = model.get('gridSize') || 1024;
        var zoomMargin = model.get('zoomMargin') || 256;

        var downloadID = 1;
        var drawIteration = 0;

        var currentGridPos = null;
        var currentTopLeft = createPoint(0, 0);
        var previousTopLeft = null;
        var downloadStats = {};

        var imageBlocks = []; 
        var ZOOM_MAX_SIZE;
        var ZOOM_MAX_CODE;
        var initialCustomContainerVisible = false;
        var tapped = false;

        var zoomValuesMap = {
            '2k': {
                value: 2048,
                code: '2k'
            },
            '4k': {
                value: 4096,
                code: '4k'
            }
        };

        var globalMousePosition = null; 
        var globalClickType = 0; 
        var isRegularZoom = true;
        var isSameImage = false;
        var isFullScreen = false;
        var currentZoomSize = 0;
        var previousZoomValue = 0;
        var thread = null;
        var transform = ['transform', 'msTransform', 'WebkitTransform', 'MozTransform', 'OTransform'];
        var transformProperty = dom.getSupportedPropertyName(transform);
        var debouncedfetchZoomedImagePart = util.debounce_throttle.debounce(fetchZoomedImagePart, 100, { leading: true, trailing: true });
        var actionButtonTemplate = document.createElement('div');
        var actionZoomButton = document.createElement('a');
        var actionZoomButton2 = document.createElement('a');
        var liElemIndex = null;
        var liElem = null;
        var showGrid = model.get('showGrid') || false;
        var showGridCoordinates = model.get('showGridCoordinates') || false;
        var isAutoZoom = false;
        var autoZoomX = 0;
        var autoZoomY = 0;
        var lastMovementCall = 0;
        var movementThrottle = 8;
        var zoomImagery = null;
        var zoomImageryRect = {
            width: 0,
            height: 0
        };
        var zoomBgLayer = null;
        var zoomImgLayer = null;
        var zoomGridLayer = null;
        var moveAutoZoomIntervalId = null;
        var eventsAttached = false;
        var zoomModeValues = {
            MOUSE_MOVE: 'mouseMove',
            MOUSE_DRAG: 'mouseDrag'
        };
        var mouseEvents = {
            MOUSE_DOWN: 'mousedown',
            MOUSE_MOVE: 'mousemove',
            MOUSE_UP: 'mouseup'
        };
        var touchEvents = {
            TOUCH_START: 'touchstart',
            TOUCH_MOVE: 'touchmove',
            TOUCH_END: 'touchend'
        };
        var mouseState = 0;
        var dragCoords = {};

        var gblPercentY;
        var gblPercentX;

        var framesHandler = config.framesHandler;
        var ariaLabel = config.ariaLabel;
        var dragDismissed = false;
        var downloadAllImagesOnZoom = false;
        var zoomRequestsStarted = [];
        var currentRequestId = 0;
        var refRequestId = 0;
        var pendingGrids = [];
        var hasPendingGridsClass = 'has-pending-grids';
        actionButtonTemplate.classList.add('cylindo-action-button-group', 'right');
        actionZoomButton.classList.add('threesixty-button', 'cylindo-action-button-icon', 'cylindo-icon-zoom-on', 'cylindo-icon-zoom-out');
        actionZoomButton2.classList.add('temporarily-hidden', 'threesixty-button-secondary', 'cylindo-action-button-icon', 'cylindo-icon-zoom-on', 'cylindo-icon-zoom-out');
        actionZoomButton2.style.display = 'none';

        actionZoomButton.setAttribute('href', 'javascript:void(0)');
        actionZoomButton2.setAttribute('href', 'javascript:void(0)');
        actionZoomButton.setAttribute('role', 'link');
        actionZoomButton2.setAttribute('role', 'link');
        actionZoomButton.setAttribute('aria-label', ariaLabel.texts.ENTER_ZOOM);
        actionZoomButton2.setAttribute('aria-label', ariaLabel.texts.EXIT_ZOOM);
        actionButtonTemplate.appendChild(actionZoomButton);
        actionButtonTemplate.appendChild(actionZoomButton2);

        ZOOM_MAX_CODE = model.get('maxZoom');
        ZOOM_MAX_SIZE = zoomValuesMap[ZOOM_MAX_CODE] ? zoomValuesMap[ZOOM_MAX_CODE].value : zoomValuesMap['2k'].value;
        previousZoomValue = ZOOM_MAX_SIZE;
        downloadStats.urls = [];
        downloadStats.totalTime = 0;
        downloadStats.totalImages = 0;
        customZoom = (customZoom && typeof customZoom === 'string') ? document.getElementById(customZoom) : null;
        isCustomZoom = customZoom !== null;
        if (isCustomZoom) {
            container = customZoom;
            container.style.overflow = 'hidden';
            initialCustomContainerVisible = getDisplayValue(container) !== 'none' ? true : false;
            updateContainerRect();
        }

        this.id = null;
        this.initialized = false;
        this.disabled = true;

        this.off = pubsub.off;
        this.on = pubsub.on;
        this.trigger = pubsub.trigger;

        this.disable = disable;
        this.destroy = destroy;
        this.startZoom = startZoom;
        this.zoomIn = zoomIn;
        this.zoomOut = zoomOut;
        this.autoZoom = autoZoom;
        this.setViewerList = setViewerList;
        this.isPanning = false;
        this.getFrameIndex = getFrameIndex;
        this.canZoom = canZoom;
        this.moveAutoZoom = moveAutoZoom;

        this.isOverlappingViewer = isOverlappingViewer;

        this.cleanUp = cleanUp;
        if (config.model.get('tooltipZoomText')) {
            this.tooltip = cylindo.getModule('tooltip.zoom').create({
                text: model.get('tooltipZoomText'),
                dom: dom
            });
        }
        if (model.get('tooltipNoZoomText')) {
            this.noZoomTooltip = cylindo.getModule('tooltip.not.available').create({
                parent: viewerContainer,
                text: model.get('tooltipNoZoomText'),
                dom: dom
            });
            if (this.noZoomTooltip) {
                this.noZoomTooltip.render();
            }
        }

        init();

        /**
         * Public function to init zoom instance
         * Prepares the grid for zooming
         * Adds zoom default tooltip
         */
        function init() {
            if (self) {
                self.id = ++Zoom.counter;
                render();
                prepareGrid();
                if (container.attachEvent) {
                    container.attachEvent('onresize', updateContainerRect);
                }
                else if (container.addEventListener) {
                    container.addEventListener('resize', updateContainerRect);
                }
                else {
                    logger.warning('The browser does not support Javascript event binding');
                }
                self.initialized = true;
            }
        }
        function prepareGrid() {
            grid = createArray(Math.ceil(zoomImageryRect.height / gridSize), Math.ceil(zoomImageryRect.width / gridSize));
            gridRectangle = createGridRect(0, 0, grid[0].length - 1, grid.length - 1);
        }

        function updateContainerRect() {
            var currentDisplay = container.style.display;
            var coverPoints = [];
            if (container.isEqualNode(viewerContainer)) {
                return;
            }
            containerRect = getRect(viewerContainer);
            customContainerRect = getRect(container);
            if (customContainerRect.width === 0 && customContainerRect.height === 0) {
                container.style.display = 'block';
                customContainerRect = getRect(container);
                container.style.display = currentDisplay;
            }
            if (customContainerRect.left <= containerRect.left &&
                customContainerRect.right > containerRect.left) {
                coverPoints.push(true);
            }
            if (customContainerRect.right >= containerRect.right &&
                customContainerRect.left < containerRect.right) {
                coverPoints.push(true);
            }
            if (customContainerRect.top <= containerRect.top &&
                customContainerRect.bottom > containerRect.top) {
                coverPoints.push(true);
            }
            if (customContainerRect.bottom >= containerRect.bottom &&
                customContainerRect.top < containerRect.bottom) {
                coverPoints.push(true);
            }
            if (coverPoints.length > 2) {
                useCustomContainer = true;
            }
            function getRect(el) {
                return util.browser.isSafari() ? util.object.getRectFixed(el) : el.getBoundingClientRect();
            }
        }
        /**
         * Render zoomImagery function for zooming
         * Checks if device is mobile to attach touch events to container
         */
        function render() {
            var zoomImageryExists = container.querySelector('.' + mainZoomElClass) !== null;
            var zoomImageryEl = document.createElement('div');
            var zoomBgLayerEl = document.createElement('div');
            var zoomImgLayerEl = document.createElement('div');
            var iconZoomOn;
            if (!zoomImageryExists) {
                zoomImageryEl.classList.add(mainZoomElClass);
                zoomImageryEl.setAttribute('role', 'img');
                zoomImageryEl.style.display = 'none';
                zoomBgLayerEl.classList.add(backgroundLayerClass);
                zoomImgLayerEl.classList.add(zoomImgLayerClass);
                zoomImageryEl.appendChild(zoomBgLayerEl);
                zoomImageryEl.appendChild(zoomImgLayerEl);
                container.appendChild(zoomImageryEl);
                if (util.browser.isMobile() && useCustomContainer) {
                    container.appendChild(actionButtonTemplate);
                }
            }
            if (util.browser.isMobile() && useCustomContainer) {
                actionZoomButton.addEventListener('touchend', zoomIn);
                actionZoomButton2.addEventListener('touchend', zoomOut);
            }
            zoomImagery = container.querySelector('.' + mainZoomElClass);
            zoomBgLayer = container.querySelector('.' + backgroundLayerClass);
            zoomImgLayer = container.querySelector('.' + zoomImgLayerClass);
            zoomImagery.setAttribute('role', 'group');
            zoomImagery.setAttribute('aria-label', ariaLabel.texts.ZOOM_CONTAINER);
            zoomImgLayer.setAttribute('role', 'img');
            zoomImgLayer.setAttribute('aria-label', ariaLabel.texts.ZOOM_IMAGE);
            updateZoomImagery(ZOOM_MAX_SIZE, ZOOM_MAX_SIZE);
            updateContainerRect();
        }

        function getZoomContainer() {
            if (isCustomZoom && useCustomContainer && !isFullScreen) {
                return container;
            }
            else {
                return viewerContainer;
            }
        }

        function startZoom(mousePosition, fullScreen, clickType) {
            var nativeTooltip = util.browser.isMobile() ? 'Drag to Pan' : 'Move the Mouse to Pan';
            var imgCtn = (mousePosition && mousePosition.frame) ? mousePosition.frame : viewerList ? viewerList.querySelector('.active') : null;
            var imgChild;
            var url;
            var i;
            var childNodesArrLen;
            var isAlternateImage = false;
            var frame = mousePosition && mousePosition.frame ? mousePosition.frame : null;
            var reloadZoomContainer = mousePosition && mousePosition.reloadZoomContainer ? mousePosition.reloadZoomContainer : false;
            var tooltipParent;
            var zoomId;
            var zoomImageIsPNG = (typeof model.get('format') === 'string' && model.get('format').toUpperCase() === 'PNG') || false;
            if (!imgCtn) {
                return;
            }
            if (mousePosition && mousePosition.useFractions) {
                mousePosition.startX = mousePosition.currentX = fractionToPixels(mousePosition.startXFraction, true);
                mousePosition.startY = mousePosition.currentY = fractionToPixels(mousePosition.startYFraction, false);
            }
            globalMousePosition = mousePosition;
            globalClickType = clickType;

            zoomBackgroundColor = model.get('zoomBackgroundColor');
            if (frame ||
                (zoomOverlapsThumbs && thumbs && !fullScreen) ||
                reloadZoomContainer) {
                forceCustomZoom(frame, reloadZoomContainer);
            }
            try {
                isFullScreen = fullScreen;
                cleanUp(isFullScreen);
                setCursor(isFullScreen, useCustomContainer, true);
                currentImgElement = imgCtn.cloneNode(true);
                img = imgCtn;
                zoomImagery.style.willChange = 'transform';
                if (typeof zoomBackgroundColor === 'string' && zoomBackgroundColor.length) {
                    zoomImagery.style.backgroundColor = '#' + zoomBackgroundColor;
                }
                else {
                    zoomImagery.style.backgroundColor = '';
                }
                if (!util.browser.isIE() && !util.browser.isEdge() && !util.browser.isMobile()) {
                    if ((util.browser.isSafari() && isFullScreen)) {
                        logger.log('CSS transform prevented for zoom on this browser version.');
                    }
                    else {
                        zoomImagery.style.transition = 'transform 25ms linear';
                    }
                }
                zoomId = container && container.getAttribute('zoom-id') ? parseInt(container.getAttribute('zoom-id'), 10) : null;
                if (zoomId !== self.id ||
                    !previousImgElement ||
                    !previousImgElement.isEqualNode(currentImgElement) ||
                    zoomBackgroundColor !== previousBackgroundColor) {
                    isSameImage = false;
                    previousImgElement = currentImgElement;
                }
                else {
                    isSameImage = true;
                }
                try {
                    container.setAttribute('zoom-id', self.id);
                } catch (ex) { }
                previousBackgroundColor = zoomBackgroundColor;
                childNodesArrLen = img.childNodes.length;
                for (i = 0; i < childNodesArrLen; i++) {
                    if (img.childNodes[i].classList &&
                        img.childNodes[i].classList.contains('cylindo-dismiss-on-zoom')) {
                        continue;
                    }
                    imgChild = img.childNodes[i];
                    imgRect = imgChild.getBoundingClientRect();
                    url = imgChild.src || (imgChild.dataset ? imgChild.dataset.src : imgChild.getAttribute('data-src'));
                }
                isRegularZoom = img.parentNode.classList.contains('cylindo-custom-list') ? false :
                    !!img.className.match(/cylindo-viewer-frame-(\d+)/ig);

                ZOOM_MAX_CODE = model.get('maxZoom');
                resetCustomZoomBtns();
                if (ZOOM_MAX_CODE === '4k') {
                    ZOOM_MAX_SIZE = currentZoomSize = zoomValuesMap[ZOOM_MAX_CODE].value;
                }
                else if (ZOOM_MAX_CODE === 'mixed' && isRegularZoom) {
                    useMixedZoomBtns();
                    ZOOM_MAX_SIZE = zoomValuesMap['4k'].value;
                    if (currentZoomSize === 0) {
                        ZOOM_MAX_CODE = '2k';
                        currentZoomSize = zoomValuesMap[ZOOM_MAX_CODE].value;
                    }
                    else {
                        ZOOM_MAX_CODE = '4k';
                        currentZoomSize = zoomValuesMap[ZOOM_MAX_CODE].value;
                    }
                }
                else {
                    ZOOM_MAX_SIZE = currentZoomSize = zoomValuesMap[ZOOM_MAX_CODE] ?
                        zoomValuesMap[ZOOM_MAX_CODE].value : zoomValuesMap['2k'].value;
                }

                if (previousZoomValue !== currentZoomSize) {
                    previousZoomValue = currentZoomSize;
                    isSameImage = false;
                }
                if (!isSameImage) {
                    currentRequestId++;
                }
                refRequestId = currentRequestId;
                if (isRegularZoom && !zoomBackgroundColor && zoomImageIsPNG) {
                    downloadAllImagesOnZoom = true;
                }
                else {
                    downloadAllImagesOnZoom = false;
                    if (zoomBgLayer) {
                        zoomBgLayer.style.display = '';
                    }
                }
                if (downloadAllImagesOnZoom && !isSameImage && zoomBgLayer) {
                    zoomBgLayer.style.display = '';
                }
                liElemIndex = img.dataset && img.dataset.index ? img.dataset.index : img.getAttribute('data-index');
                liElem = img;
                try {
                    isAlternateImage = img.dataset.isAlternateImage ? +img.dataset.isAlternateImage : 0;
                }
                catch (ex) {
                    isAlternateImage = img.getAttribute('data-is-alternate-image') ?
                        +img.getAttribute('data-is-alternate-image') : 0;
                }

                if (imgRect.height > imgRect.width) {
                    updateZoomImagery((0.5 + (currentZoomSize * (imgRect.width / imgRect.height))) << 0, currentZoomSize);
                }
                else if (imgRect.width > imgRect.height) {
                    updateZoomImagery(currentZoomSize, (0.5 + (currentZoomSize * (imgRect.height / imgRect.width))) << 0);
                }
                else {
                    updateZoomImagery(currentZoomSize, currentZoomSize);
                }

                if (isCustomZoom) {
                    if (getDisplayValue(container) === 'none') {
                        dom.fadeIn(container);
                    }
                }
                containerRect = viewerContainer.getBoundingClientRect();

                tags.send(tags.events.ZOOM);

                processZoom(mousePosition, imgChild, imgRect, refRequestId);

                if (self && self.tooltip) {
                    tooltipParent = isCustomZoom && !isOverlappingViewer() ?
                        viewPort.querySelector('.cylindo-viewer-container') :
                        zoomImagery.parentNode;
                    self.tooltip.remove();
                    self.tooltip.setParent(tooltipParent);
                    self.tooltip.render();
                    if (dragDismissed && !model.get('tooltipZoomTextFixed')) {
                        model.set('tooltipZoomText', nativeTooltip, true);
                    }
                    self.tooltip.show(model.get('tooltipZoomText'));
                }
                self.trigger(self.events.ENABLED, {
                    mousePosition: mousePosition,
                    clickType: clickType,
                    index: liElemIndex,
                    li: liElem,
                    x: getXFraction(),
                    y: getYFraction(),
                });

                logger.event('Zoom started at point: ' + JSON.stringify(mousePosition));
            }
            catch (ex) {
                disable();
                cleanAutoZoom();
                logger.error(ex);
            }
        }

        function createArray(length) {
            var arr = new Array(length || 0),
                i = length;

            if (arguments.length > 1) {
                var args = Array.prototype.slice.call(arguments, 1);
                while (i--) arr[length - 1 - i] = createArray.apply(self, args);
            }

            return arr;
        }
        function printGrid() {
            if (grid) {
                logger.log('Grid has dimensions: (' + grid.length + ', ' + grid[0].length + ')');
                var x = 0;
                var y = 0;
                for (; x < grid[0].length; x++) {
                    for (y = 0; y < grid.length; y++) {
                        if (grid[y][x]) {
                            if (grid[y][x].isFinishedDownloading) {
                                logger.log('(' + x + ', ' + y + ') ', JSON.parse(JSON.stringify(grid[y][x])));
                            }
                            else {
                                logger.log('(' + x + ', ' + y + ') is downloading');
                            }

                        }
                        else {
                            logger.log('(' + x + ', ' + y + ')', grid[y][x]);
                        }
                    }
                }
            }
            else {
                logger.log('Grid is not initialized');
            }
        }

        function downloadVerticalGridRect(gridRect, refRequestId) {
            var allBlocksDeferred = util.promise.create();
            var blockDeferreds = [];
            var x, y;
            var block = null;
            var isPartOfFirstGrid = false;
            if (gridRect) {
                gridRect.compress(gridRectangle);
                for (x = gridRect.minX; x <= gridRect.maxX; x++) {
                    for (y = gridRect.minY; y <= gridRect.maxY; y++) {
                        if (!grid[y][x]) {
                            if (!block) {
                                block = createGridRect(x, y, x, y);
                            }
                            else {
                                block.include(x, y);
                            }
                        }
                        else {
                            if (block) {
                                blockDeferreds.push(downloadBlockImages(block));
                                block = null;
                            }
                        }
                    }
                    if (block) {
                        blockDeferreds.push(downloadBlockImages(block));
                        block = null;
                    }
                }
            }
            function downloadBlockImages(block) {
                var blockDeferred = util.promise.create();
                var subBlocksDeferreds = [];
                var x, y;
                var subblock = null;
                for (x = block.minX; x <= block.maxX; x++) {
                    for (y = block.minY; y <= block.maxY; y++) {
                        subblock = createGridRect(x, y, x, y);
                        subBlocksDeferreds.push(downloadGridImage(subblock, refRequestId, isPartOfFirstGrid));
                    }
                }
                Promise.all(subBlocksDeferreds).then(function () {
                    blockDeferred.resolve();
                });
                return blockDeferred;
            }
            Promise.all(blockDeferreds).then(function () {
                allBlocksDeferred.resolve();
            });
            return allBlocksDeferred;
        }

        function downloadHorizontalGridRect(gridRect, refRequestId) {
            var allBlocksDeferred = util.promise.create();
            var blockDeferreds = [];
            var x, y;
            var block = null;
            var isPartOfFirstGrid = false;
            if (gridRect) {
                gridRect.compress(gridRectangle);
                for (y = gridRect.minY; y <= gridRect.maxY; y++) {
                    for (x = gridRect.minX; x <= gridRect.maxX; x++) {
                        if (!grid[y][x]) {
                            if (!block) {
                                block = createGridRect(x, y, x, y);
                            }
                            else {
                                block.include(x, y);
                            }
                        }
                        else {
                            if (block) {
                                blockDeferreds.push(downloadBlockImages(block));
                                block = null;
                            }
                        }
                    }
                    if (block) {
                        blockDeferreds.push(downloadBlockImages(block));
                        block = null;
                    }
                }
            }
            function downloadBlockImages(block) {
                var blockDeferred = util.promise.create();
                var subBlocksDeferreds = [];
                var x, y;
                var subblock = null;
                for (y = block.minY; y <= block.maxY; y++) {
                    for (x = block.minX; x <= block.maxX; x++) {
                        subblock = createGridRect(x, y, x, y);
                        subBlocksDeferreds.push(downloadGridImage(subblock, refRequestId, isPartOfFirstGrid));
                    }
                }
                Promise.all(subBlocksDeferreds).then(function () {
                    blockDeferred.resolve();
                });
                return blockDeferred;
            }
            Promise.all(blockDeferreds).then(function () {
                allBlocksDeferred.resolve();
            });
            return allBlocksDeferred;
        }
        function downloadGridImage(gridRect, refRequestId, isPartOfFirstGrid) {
            if (gridRect) {
                return downloadImage(gridRect, downloadID++, refRequestId, isPartOfFirstGrid);
            }
            return null;
        }
        function downloadImage(gridRect, id, refRequestId, isPartOfFirstGrid) {
            var imgLarge = network.resolveProtocol(img.dataset ? img.dataset.zoomImage : img.getAttribute('data-zoom-image'));
            var screenRect = gridToScreen(gridRect);
            var url;
            var zoomImg;
            var x, y;
            var zoomEvtString = '';
            var deferred = util.promise.create();

            if (id && container) {
                if (screenRect) {
                    gridRect = screenToGrid(screenRect);
                    if ((screenRect.x + screenRect.w) > zoomImageryRect.width) {
                        screenRect.w = zoomImageryRect.width - screenRect.x;
                    }
                    if ((screenRect.y + screenRect.h) > zoomImageryRect.height) {
                        screenRect.h = zoomImageryRect.height - screenRect.y;
                    }
                    zoomImg = new Image();
                    zoomImg.id = 'zoom-img-' + id;
                    zoomImg.setAttribute('aria-hidden', 'true');
                    zoomImg.setAttribute('alt', null);
                    zoomImg.setAttribute('role', 'img');
                    zoomImg.classList.add('zoom-img');
                    if (isPartOfFirstGrid) {
                        pendingGrids[refRequestId] = pendingGrids[refRequestId] ||  [];
                        pendingGrids[refRequestId].push(zoomImg);
                    }

                    try {
                        zoomImg.dataset.zoomTileXMin = gridRect.minX;
                        zoomImg.dataset.zoomTileYMin = gridRect.minY;
                        zoomImg.dataset.zoomTileXMax = gridRect.maxX;
                        zoomImg.dataset.zoomTileYMax = gridRect.maxY;
                    }
                    catch (ex) {
                        zoomImg.setAttribute('data-zoom-tile-x-min', gridRect.minX);
                        zoomImg.setAttribute('data-zoom-tile-y-min', gridRect.minY);
                        zoomImg.setAttribute('data-zoom-tile-x-max', gridRect.maxX);
                        zoomImg.setAttribute('data-zoom-tile-y-max', gridRect.maxY);
                    }

                    zoomImg.requestTime = (Date.now());
                    zoomImg.blockCount = ((gridRect.maxX - gridRect.minX) * (gridRect.maxY - gridRect.minY));
                    zoomImg.blockSize = (gridRect.maxX - gridRect.minX) + ' x ' + (gridRect.maxY - gridRect.minY);
                    zoomImg.imgName = 'img ' + id + ' (' + (gridRect.maxX - gridRect.minX) + ' x ' + (gridRect.maxY - gridRect.minY) + ')';
                    zoomImg.gridRect = gridRect;
                    zoomImg.screenRect = screenRect;
                    zoomImg.isFinishedDownloading = false;
                    zoomImg.processFinished = false;
                    zoomImg.deferred = deferred;
                    zoomImg.wasDrawn = false;
                    zoomImg.refRequestId = refRequestId;

                    imageBlocks.push(zoomImg);

                    for (x = gridRect.minX; x < gridRect.maxX; x++) {
                        for (y = gridRect.minY; y < gridRect.maxY; y++) {
                            try {
                                grid[y][x] = zoomImg;
                            }
                            catch (ex) {
                                logger.error('Cannot set image for the provided coordinates y = ' + y + ' x = ' + x, ex);
                                continue;
                            }
                        }
                    }

                    if (isRegularZoom) {
                        url = imgLarge.replace('__COORDS__', screenRect.x + ',' + screenRect.y + ',' + screenRect.w + ',' + screenRect.h);
                        url = network.addQueryString(url, {
                            zoom: ZOOM_MAX_CODE,
                        });
                        url = typeof zoomBackgroundColor === 'string' && zoomBackgroundColor.length ? network.addQueryString(url, {
                            background: zoomBackgroundColor,
                        }) : url;
                        logger.event('Downloading rect: (x: ' + screenRect.x + ', y: ' + screenRect.y + ', w: ' + screenRect.w + ', h: ' + screenRect.h + ')');

                        if (window.XDomainRequest) {
                            zoomImg.crossOrigin = 'Anonymous';
                            zoomImg.onload = function () {
                                imageLoaded(zoomImg);
                            };
                            zoomImg.onerror = onImageError;
                            zoomImg.src = url;
                        } else {
                            network.requestImage(url, processImageBlob, onImageError);
                        }

                    }

                    if (!cylindoZoomImagesLoaded) {
                        cylindoZoomImagesLoaded = [];
                    }
                    zoomEvtString = ([screenRect.x, screenRect.y, screenRect.w, screenRect.h]).join(',');
                    cylindoZoomImagesLoaded.push(zoomEvtString);
                }
            }

            function processImageBlob(data) {
                var dataURL;
                var blob;
                try {
                    dataURL = URL.createObjectURL(data);
                }
                catch (ex) {
                    blob = new Blob([data]);
                    dataURL = webkitURL.createObjectURL(blob);
                }
                zoomImg.style.position = 'absolute';
                zoomImg.style.left = zoomImg.screenRect.x + 'px';
                zoomImg.style.top = zoomImg.screenRect.y + 'px';
                zoomImg.src = dataURL;
                downloadStats.urls.push(dataURL);

                imageLoaded(zoomImg);
            }
            function onImageError() {
                if (zoomImg.deferred) {
                    zoomImg.deferred.reject();
                }
                zoomImg = null;
                for (x = gridRect.minX; x < gridRect.maxX; x++) {
                    for (y = gridRect.minY; y < gridRect.maxY; y++) {
                        grid[y][x] = null;
                    }
                }
            }
            return deferred;
        }

        function downloadFullImage(mousePosition, imgRect, rect, deferred) {
            var imgLarge = network.resolveProtocol(img.dataset ? img.dataset.zoomImage : img.getAttribute('data-zoom-image'));
            var zoomImage = new Image();
            var childImage = img.childNodes[0];
            if (!isRegularZoom) {
                clearLayer(zoomBgLayer);
                clearLayer(zoomImgLayer);
                zoomImage.addEventListener('load', function () {
                    updateZoomImagery(zoomImage.naturalWidth, zoomImage.naturalHeight);
                    adjustZoomImagery(mousePosition, imgRect, rect);
                    drawImage(zoomImage, 0, 0, zoomImageryRect.width, zoomImageryRect.height);
                    largeStaticImage = zoomImage;
                    deferred.resolve();
                });
                zoomImage.onerror = function () {
                    logger.error('Zoom image not found');
                    deferred.resolve();
                    disable();
                };
                zoomImage.src = imgLarge;
            }
        }
        function imageLoaded(zoomImg) {
            var time;
            if (zoomImg) {
                if (typeof zoomImg.requestTime !== 'undefined') {
                    time = (Date.now() - zoomImg.requestTime);
                    downloadStats.totalImages++;
                    downloadStats.totalTime += time;
                }
                if (typeof zoomImg.isFinishedDownloading !== 'undefined') {
                    zoomImg.isFinishedDownloading = true;
                }

                zoomImg.drawIteration = drawIteration;

                if (window.XDomainRequest) {
                    drawContents();
                }
                else {
                    zoomImg.addEventListener('load', drawContents, { once: true });
                    if (zoomImg.deferred) {
                        zoomImg.deferred.then(function () {
                            var tmpGrid = null; 
                            var x = 0;
                            var y = 0;
                            var refRequestId = zoomImg.refRequestId; 
                            var currentGridRec = getCurrentGridRect();
                            var minYPos = 0;
                            var minXPos = 0;
                            var maxYPos = Math.ceil(zoomImageryRect.height / gridSize) - 1;
                            var maxXPos = Math.ceil(zoomImageryRect.width / gridSize - 1);
                            var requestingImgs;
                            var oPreviousDeferred = null;
                            var getCopyOfCurrentGridRec = function () {
                                return typeof currentGridRec === 'object' ? util.object.extend({}, currentGridRec) : {};
                            };
                            var lastRequest = null;
                            var logMsgs = {
                                DOWNLOADING_ALL_IMGS: 'Trying to download the rest of images for zoom request',
                                ALL_IMAGES_DOWNLOADED: 'All zoom images have been downloaded for this request: ',
                                DOWNLOADING_MISSING_GRID: 'Downloading missing grid image.',
                                ERROR: 'Cannot continue downloading grids.',
                                NO_REMAINING_IMAGES: 'There are no remaining images to be dowloaded, finishing zoom process',
                            };
                            if (isSameRequest()) {
                                if (pendingGrids[refRequestId].length > 0) {
                                    return;
                                }
                                else {
                                    if (zoomImgLayer && zoomImgLayer.classList.contains(hasPendingGridsClass)) {
                                        zoomImgLayer.classList.remove(hasPendingGridsClass);
                                    }
                                }
                                if (zoomRequestsStarted.indexOf(refRequestId) === -1) {
                                    zoomRequestsStarted.push(refRequestId);
                                    logMsg(logMsgs.DOWNLOADING_ALL_IMGS, refRequestId);
                                    if (downloadAllImagesOnZoom) {
                                        currentGridRec.compress(gridRectangle);
                                        do {
                                            requestingImgs = false;
                                            if (isSameRequest()) {
                                                tmpGrid = getCopyOfCurrentGridRec();
                                                if (tmpGrid.minX > 0) {
                                                    tmpGrid.maxX = tmpGrid.minX;
                                                    tmpGrid.minX = tmpGrid.minX - 1;
                                                    lastRequest = tryToDetectImgs(true, tmpGrid);
                                                    requestingImgs = true;
                                                }
                                                tmpGrid = getCopyOfCurrentGridRec();
                                                if (tmpGrid.maxX < maxXPos) {
                                                    tmpGrid.minX = tmpGrid.maxX;
                                                    tmpGrid.maxX = tmpGrid.maxX + 1;
                                                    lastRequest = tryToDetectImgs(true, tmpGrid);
                                                    requestingImgs = true;
                                                }
                                                tmpGrid = getCopyOfCurrentGridRec();
                                                if (tmpGrid.minY > 0) {
                                                    tmpGrid.maxY = tmpGrid.minY;
                                                    tmpGrid.minY = tmpGrid.minY - 1;
                                                    lastRequest = tryToDetectImgs(false, tmpGrid);
                                                    requestingImgs = true;
                                                }
                                                tmpGrid = getCopyOfCurrentGridRec();
                                                if (tmpGrid.maxY < maxYPos) {
                                                    tmpGrid.minY = tmpGrid.maxY;
                                                    tmpGrid.maxY = tmpGrid.maxY + 1;
                                                    lastRequest = tryToDetectImgs(false, tmpGrid);
                                                    requestingImgs = true;
                                                }
                                                if (requestingImgs) {
                                                    currentGridRec.minX = currentGridRec.minX - 1 < 0 ? currentGridRec.minX : currentGridRec.minX - 1;
                                                    currentGridRec.maxX = currentGridRec.maxX + 1 > maxXPos ? currentGridRec.maxX : currentGridRec.maxX + 1;
                                                    currentGridRec.minY = currentGridRec.minY - 1 < 0 ? currentGridRec.minY : currentGridRec.minY - 1;
                                                    currentGridRec.maxY = currentGridRec.maxY + 1 > maxYPos ? currentGridRec.maxY : currentGridRec.maxY + 1;
                                                }
                                                else {
                                                    if (lastRequest) {
                                                        lastRequest.then(tryToDetectMissingImgs).catch(logMsg.bind(null, logMsgs.ERROR, refRequestId));
                                                    }
                                                    else {
                                                        if (zoomHasBeenCompleted()) {
                                                            logMsg(logMsgs.NO_REMAINING_IMAGES, refRequestId);
                                                            finishZoomProcess();
                                                        }
                                                    }
                                                }
                                            }
                                        } while (requestingImgs);
                                    }
                                }
                                else {
                                    if (zoomHasBeenCompleted()) {
                                        finishZoomProcess();
                                    }
                                }
                            }
                            function finishZoomProcess() {
                                if (zoomRequestsStarted.indexOf(refRequestId) !== -1 && isSameRequest()) {
                                    logMsg(logMsgs.ALL_IMAGES_DOWNLOADED, refRequestId);
                                    if (zoomBgLayer && downloadAllImagesOnZoom) {
                                        zoomBgLayer.style.display = 'none';
                                    }
                                }

                            }
                            function zoomHasBeenCompleted() {
                                for (x = minXPos; x <= maxXPos; x++) {
                                    for (y = minYPos; y <= maxYPos; y++) {
                                        if (!grid[y][x] || !grid[y][x].processFinished) {
                                            return false;
                                        }
                                    }
                                }
                                return true;
                            }
                            function tryToDetectImgs(isVertical, tmpGrid) {
                                if (oPreviousDeferred === null) {
                                    oPreviousDeferred = tryToDownloadGrid();
                                }
                                else {
                                    oPreviousDeferred = oPreviousDeferred.then(function () {
                                        var deferred = tryToDownloadGrid();
                                        return deferred;
                                    });
                                }
                                function tryToDownloadGrid() {
                                    var deferred = util.promise.create();
                                    if (isSameRequest()) {
                                        return isVertical ? downloadVerticalGridRect(tmpGrid, refRequestId) : downloadHorizontalGridRect(tmpGrid, refRequestId);
                                    }
                                    deferred.reject();
                                    return deferred;
                                }
                                return oPreviousDeferred;
                            }
                            function tryToDetectMissingImgs() {
                                for (x = minXPos; x <= maxXPos; x++) {
                                    for (y = minYPos; y <= maxYPos; y++) {
                                        if (!grid[y][x]) {
                                            logMsg(logMsgs.DOWNLOADING_MISSING_GRID, {
                                                x: x,
                                                y: y,
                                            });
                                            tmpGrid = createGridRect(x, y, x, y);
                                            if (isSameRequest()) {
                                                tryToDetectImgs(true, tmpGrid, refRequestId);
                                            }
                                        }
                                    }
                                }
                            }
                            function isSameRequest() {
                                return refRequestId === currentRequestId;
                            }
                            function logMsg(msg, parameters) {
                                logger.log(msg, parameters);
                            }
                        });
                    }
                }
            }
        }
        function detectNewImages(refRequestId) {
            var newGrid = getCurrentGridRect();
            var x1, x2;
            var y1, y2;
            var verticalGrid, horizontalGrid;
            var screenRect;
            newGrid.compress(gridRectangle);
            x1 = currentGridPos.minX - newGrid.minX;
            x2 = currentGridPos.maxX - newGrid.maxX;
            if (x1 > 0) {
                verticalGrid = createGridRect(currentGridPos.minX - x1, currentGridPos.minY, currentGridPos.minX, currentGridPos.maxY);
                downloadVerticalGridRect(verticalGrid, refRequestId);
                downloadVerticalGridRect(newGrid, refRequestId);
            }
            if (x2 < 0) {
                verticalGrid = createGridRect(currentGridPos.maxX - x2, currentGridPos.minY, currentGridPos.maxX + 1, currentGridPos.maxY);
                downloadVerticalGridRect(verticalGrid, refRequestId);
                downloadVerticalGridRect(newGrid, refRequestId);
            }
            y1 = currentGridPos.minY - newGrid.minY;
            y2 = currentGridPos.maxY - newGrid.maxY;
            if (y1 > 0) {
                horizontalGrid = createGridRect(newGrid.minX, newGrid.minY - y1, newGrid.maxX, newGrid.minY);
                downloadHorizontalGridRect(horizontalGrid, refRequestId);
                downloadHorizontalGridRect(newGrid, refRequestId);
            }
            if (y2 < 0) {
                horizontalGrid = createGridRect(newGrid.minX, currentGridPos.maxY + 1, newGrid.maxX, newGrid.maxY);
                downloadHorizontalGridRect(horizontalGrid, refRequestId);
                downloadHorizontalGridRect(newGrid, refRequestId);
            }

            currentGridPos = newGrid;
        }
        function getCurrentViewport() {
            var result = null;
            var doubleMargin = (2 * zoomMargin);
            var x, y, w, h;

            x = (currentTopLeft.x - zoomMargin) < 0 ? 0 : currentTopLeft.x - zoomMargin;
            y = (currentTopLeft.y - zoomMargin) < 0 ? 0 : currentTopLeft.y - zoomMargin;
            w = ((useCustomContainer && !isFullScreen) ? customContainerRect.width : containerRect.width) + doubleMargin;
            h = ((useCustomContainer && !isFullScreen) ? customContainerRect.height : containerRect.height) + doubleMargin;
            return createScreenRect(x, y, w, h);
        }
        function getCurrentGridRect() {
            var gridRect = null;
            var screenRect = getCurrentViewport();

            if (screenRect) {
                gridRect = screenToGrid(screenRect);
            }
            return gridRect;
        }
        function screenToGrid(screenRect) {
            var minX = Math.floor(screenRect.x / gridSize);
            var minY = Math.floor(screenRect.y / gridSize);
            var maxX = Math.floor((screenRect.w + (screenRect.x % gridSize)) / gridSize) + minX;
            var maxY = Math.floor((screenRect.h + (screenRect.y % gridSize)) / gridSize) + minY;
            return createGridRect(minX, minY, maxX, maxY);
        }
        function gridToScreen(gridRect) {
            var x = gridRect.minX * gridSize;
            var y = gridRect.minY * gridSize;
            var w = ((gridRect.maxX * gridSize) + gridSize) - x;
            var h = ((gridRect.maxY * gridSize) + gridSize) - y;
            return createScreenRect(x, y, w, h);
        }
        function createGridRect(minX, minY, maxX, maxY) {
            var rect = {};
            rect.minX = minX;
            rect.minY = minY;
            rect.maxX = maxX;
            rect.maxY = maxY;
            rect.print = function (str) {
                logger.log(str + 'min(' + rect.minX + ', ' + rect.minY + '), max(' + rect.maxX + ', ' + rect.maxY + ')');
            };
            rect.include = function (x, y) {
                if (x < rect.minX) {
                    rect.minX = x;
                }
                else if (x > rect.maxX) {
                    rect.maxX = x;
                }
                if (y < rect.minY) {
                    rect.minY = y;
                }
                else if (y > rect.maxY) {
                    rect.maxY = y;
                }
            };
            rect.compress = function (other) {
                rect.minX = Math.max(rect.minX, 0);
                rect.minY = Math.max(rect.minY, 0);
                rect.maxX = Math.min(rect.maxX, other.maxX);
                rect.maxY = Math.min(rect.maxY, other.maxY);
            };
            return rect;
        }
        function createScreenRect(x, y, w, h) {
            var rect = {};
            rect.x = x;
            rect.y = y;
            rect.w = w;
            rect.h = h;
            rect.print = function (str) {
                logger.log(str + '(' + rect.x + ', ' + rect.y + ', ' + rect.w + ', ' + rect.h + ')');
            };
            return rect;
        }
        function createPoint(x, y) {
            var point = {};
            point.x = x;
            point.y = y;
            point.print = function (str) {
                logger.log(str + '(' + point.x + ', ' + point.y + ')');
            };
            return point;

        }
        function compareGridRectangles(a, b) {
            if (a && b) {
                return (a.minX === b.minX &&
                    a.minY === b.minY &&
                    a.maxX === b.maxX &&
                    a.maxY === b.maxY);
            }
            return false;
        }
        function isWithinGridRectangle(large, small) {
            if (large && small) {
                return (small.minX >= large.minX &&
                    small.maxX <= large.maxX &&
                    small.minY >= large.minX &&
                    small.maxY <= large.maxY);
            }
            return false;
        }
        function drawImage(img, x, y, width, height) {
            if (zoomImgLayer && img) {
                if (img.wasDrawn) {
                    return;
                }
                img.wasDrawn = true;
                img.style.position = 'absolute';
                img.style.left = x + 'px';
                img.style.top = y + 'px';
                img.style.width = width;
                img.style.height = height;
                zoomImgLayer.appendChild(img);
                img.processFinished = true;
                if (typeof img.refRequestId !== 'undefined' && 
                    pendingGrids[img.refRequestId] &&
                    pendingGrids[img.refRequestId].indexOf(img) !== -1) {
                    pendingGrids[img.refRequestId].splice(pendingGrids[img.refRequestId].indexOf(img), 1);
                }
                if (img.deferred) {
                    img.deferred.resolve();
                }
            }
        }
        function drawScene() {
            if (isRegularZoom) { 
                drawBackground().then(drawContents.bind(self));
            }
            else {
                drawContents();
            }
        }
        function drawDebugGrid() {
            if (zoomGridLayer) {
                zoomGridLayer.innerHTML = '';
            }
            if (isRegularZoom) {
                if (showGrid === true) {
                    drawGrid(gridSize);
                    if (showGridCoordinates === true) {
                        drawGridCoordinates();
                    }
                }
            }
        }
        function drawGrid(size) {
            var gridEl, x, y;
            zoomGridLayer = zoomGridLayer || document.createElement('div');
            if (grid && zoomImagery && zoomGridLayer) {
                for (x = 0; x < grid[0].length; x++) {
                    for (y = 0; y < grid.length; y++) {
                        gridEl = document.createElement('div');
                        gridEl.classList.add(zoomGridClass + y + '-' + x);
                        gridEl.style.color = 'red';
                        gridEl.style.fontFamily = gridFont;
                        gridEl.style.fontSize = '10px';
                        gridEl.style.textAlign = 'center';
                        gridEl.style.border = 'thin solid grey';
                        gridEl.style.left = (size * x) + 'px';
                        gridEl.style.top = (size * y) + 'px';
                        gridEl.style.lineHeight = size + 'px';
                        try {
                            gridEl.dataset.x = x;
                            gridEl.dataset.y = y;
                        }
                        catch (ex) {
                            gridEl.setAttribute('data-x', x);
                            gridEl.setAttribute('data-y', y);
                        }
                        setElMeasurements(gridEl, size - 2, size - 2);
                        zoomGridLayer.appendChild(gridEl);
                    }
                }
                zoomImagery.appendChild(zoomGridLayer);
            }
            else {
                logger.log('Grid is not initialized');
            }
        }
        function drawGridCoordinates() {
            var gridElements, gridElementsLen, i, gridEl, x, y;
            if (grid && zoomImagery && zoomGridLayer) {
                gridElements = zoomGridLayer.querySelectorAll('[class*="' + zoomGridClass + '"]');
                gridElementsLen = gridElements.length;
                for (i = 0; i < gridElementsLen; i++) {
                    gridEl = gridElements[i];
                    try {
                        x = gridEl.dataset.x;
                        y = gridEl.dataset.y;
                    }
                    catch (ex) {
                        x = gridEl.getAttribute('data-x');
                        y = gridEl.getAttribute('data-y');
                    }
                    gridEl.innerHTML = '(' + x + ',' + y + ')';
                }
            }
        }

        function renderSingleImage(child, deferred) {
            var drawable = new Image();
            var position;
            var desiredX = 0;
            var natHeight, natWidth;
            var zoomBgLayerWidth;
            if (isSameImage) {
                deferred.resolve();
                return;
            }

            drawable.onload = onDrawableLoad;

            if (child instanceof Image) {
                drawable.src = child.src;
                natHeight = child.naturalHeight;
                natWidth = child.naturalWidth;
            }
            function onDrawableLoad() {
                if (drawable && zoomImagery) {
                    if (drawImageBackground === true) {
                        setBgLayer('', '', '', '');
                        drawable.width = zoomImageryRect.width;
                        drawable.height = zoomImageryRect.height;
                        drawable.setAttribute('aria-hidden', 'true');
                        drawable.setAttribute('alt', null);
                        drawable.setAttribute('role', 'img');
                        zoomBgLayer.appendChild(drawable);
                    }
                    else {
                        zoomImagery.style.backgroundColor = 'blue';
                    }
                    drawable.onload = '';
                }
                deferred.resolve();
            }

            function setBgLayer(backgroundImage, backgroundPositionX, backgroundSize, repeat) {
                zoomBgLayer.style.backgroundImage = backgroundImage;
                zoomBgLayer.style.backgroundPositionX = backgroundPositionX;
                zoomBgLayer.style.backgroundSize = backgroundSize;
                zoomBgLayer.style.repeat = repeat;
            }
        }
        function drawBackground() {
            var i;
            var childernArrLen = img.childNodes.length;
            var deferred = util.promise.create();
            for (i = 0; i < childernArrLen; i++) {
                if (img.childNodes[i].classList &&
                    img.childNodes[i].classList.contains('cylindo-dismiss-on-zoom')) {
                    continue;
                }
                renderSingleImage(img.childNodes[i], deferred);
            }
            return deferred;
        }
        function drawContents() {
            var x = 0;
            var y = 0;
            var image;
            if (isRegularZoom && grid) {
                drawIteration++;
                for (x = 0; x < grid[0].length; x++) {
                    for (y = 0; y < grid.length; y++) {
                        if (grid[y][x] && grid[y][x].isFinishedDownloading && grid[y][x].drawIteration < drawIteration) {
                            try {
                                image = grid[y][x];
                                drawImage(image, image.screenRect.x, image.screenRect.y, image.screenRect.w, image.screenRect.h);
                                grid[y][x].drawIteration = drawIteration;
                                image = null;
                            }
                            catch (ex) {
                                image = null;
                                continue;
                            }
                        }
                    }
                }
            }
        }
        function isRightClick(mouseEvent) {
            if ('which' in mouseEvent) {
                return (mouseEvent.which == 3);
            }
            else if ('button' in mouseEvent) {
                return (mouseEvent.button == 2);
            }
            return false;
        }

        function dragStart(evt) {
            evt.preventDefault(); 


            if (evt.type === mouseEvents.MOUSE_DOWN) {
                dragCoords.initialX = evt.clientX;
                dragCoords.initialY = evt.clientY;
                dragCoords.startX = evt.clientX;
                dragCoords.startY = evt.clientY;
            }
            else {
                dragCoords.initialX = evt.touches[0].screenX;
                dragCoords.initialY = evt.touches[0].screenY;
                dragCoords.startX = evt.touches[0].screenX;
                dragCoords.startY = evt.touches[0].screenY;
            }
            mouseState = 1;
        }
        function dragMove(evt) {

            var rect = isCustomZoom && !isFullScreen ? customContainerRect : containerRect;
            var deltaX, deltaY, deltaXPer, deltaYPer;
            var dragFactor = evt ? evt.type === mouseEvents.MOUSE_MOVE ? 1.1 : 1.25 : 0;
            if (mouseState === 0) {
                return;
            }
            if (evt.type === mouseEvents.MOUSE_MOVE) {
                deltaX = evt.clientX - dragCoords.startX;
                deltaY = evt.clientY - dragCoords.startY;
            }
            else {
                deltaX = evt.touches[0].screenX - dragCoords.startX;
                deltaY = evt.touches[0].screenY - dragCoords.startY;
            }
            if (lastMovementCall && (Date.now() - lastMovementCall) < movementThrottle) {
                return;
            }
            lastMovementCall = Date.now();
            preventEvents(evt);
            if (typeof dragCoords.startX === 'undefined' || typeof dragCoords.startY === 'undefined') {
                return;
            }
            leftOffset -= (deltaX * dragFactor);
            topOffset -= (deltaY * dragFactor);
            leftOffset = checkHorizontalLimits(leftOffset, rect);
            topOffset = checkVerticalLimits(topOffset, rect);
            moveZoom(leftOffset, topOffset);
            if (evt.type === mouseEvents.MOUSE_MOVE) {
                dragCoords.startX = evt.clientX;
                dragCoords.startY = evt.clientY;
            }
            else {
                dragCoords.startX = evt.touches[0].screenX;
                dragCoords.startY = evt.touches[0].screenY;
            }
            deltaXPer = -1 * deltaX * dragFactor;
            deltaYPer = -1 * deltaY * dragFactor;
            gblPercentX = currentTopLeft.x !== leftOffset ?
                gblPercentX + (deltaXPer / (zoomImageryRect.width - imgRect.width)) :
                gblPercentX;
            gblPercentY = currentTopLeft.y !== topOffset ?
                gblPercentY + (deltaYPer / (zoomImageryRect.height - imgRect.height)) :
                gblPercentY;
            currentTopLeft.x = leftOffset;
            currentTopLeft.y = topOffset;
            if (isRegularZoom) {
                self.trigger(self.events.ZOOM, {
                    index: liElemIndex,
                    files: cylindoZoomImagesLoaded,
                    x: getXFraction(),
                    y: getYFraction(),
                });
            }
            else {
                self.trigger(self.events.ZOOM, {
                    index: liElemIndex,
                    url: network.resolveProtocol(img.dataset ? img.dataset.zoomImage : img.getAttribute('data-zoom-image')),
                    x: getXFraction(),
                    y: getYFraction(),
                });
            }
            tryToDownloadNewImages(currentRequestId);
        }
        function dragEnd(evt) {
            var useFractions = true;
            var deltaY, deltaX;
            mouseState = 0;
            if (evt && evt.type === touchEvents.TOUCH_END) {
                if (!tapped) {
                    tapped = setTimeout(function () {
                        tapped = false;
                    }, 300);
                }
                else {
                    clearTimeout(tapped);
                    tapped = false;
                    disable();
                }
            }
            else {
                deltaX = evt.clientX - dragCoords.initialX;
                deltaY = evt.clientY - dragCoords.initialY;
                if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
                    onMouseUp(evt, useFractions);
                }
            }
        }
        function checkHorizontalLimits(leftOffset, rect) {
            var rightLimit = zoomImageryRect.width - rect.width;
            leftOffset = Math.max(leftOffset, 0);
            leftOffset = Math.min(leftOffset, rightLimit);
            return leftOffset;
        }
        function checkVerticalLimits(topOffset, rect) {
            var topLimit = zoomImageryRect.height - rect.height;
            topOffset = Math.max(topOffset, 0);
            topOffset = Math.min(topOffset, topLimit);
            return topOffset;
        }

        function getWidthDifference() {
            return isCustomZoom && !isFullScreen ?
                (imgRect.left - customContainerRect.left) :
                (containerRect.width - imgRect.width) / 2;
        }

        function getHeightDifference() {
            return isCustomZoom && !isFullScreen ?
                (imgRect.top - customContainerRect.top) :
                (containerRect.height - imgRect.height) / 2;
        }

        function getRightLimit() {
            return (isCustomZoom && !forceCustomZoom && !isFullScreen) ?
                zoomImageryRect.width - customContainerRect.width :
                zoomImageryRect.width - imgRect.width;
        }

        function getTopLimit() {
            return (isCustomZoom && !forceCustomZoom && !isFullScreen) ?
                zoomImageryRect.height - customContainerRect.height :
                zoomImageryRect.height - imgRect.height;
        }
        function moveZoom(leftOffset, topOffset) {
            if (leftOffset > 0) {
                leftOffset = (leftOffset * -1);
            }
            else if (leftOffset < 0) {
                leftOffset = (leftOffset * -1 / 2);
            }
            if (topOffset > 0) {
                topOffset = (topOffset * -1);
            }
            else if (topOffset < 0) {
                topOffset = (topOffset * -1 / 2);
            }
            leftOffset = Math.round(leftOffset).toString();
            topOffset = Math.round(topOffset).toString();

            if (transformProperty) {
                zoomImagery.style[transformProperty] = 'translate(' + leftOffset + 'px, ' + topOffset + 'px)';
            }
            else {
                if (window.XDomainRequest) {
                    zoomImagery.style['-ms-transform'] = 'translate(' + leftOffset + 'px, ' + topOffset + 'px)';
                }
                else {
                    zoomImagery.style.transform = 'translate(' + leftOffset + 'px, ' + topOffset + 'px)';
                }
            }
        }
        function mouseMove(mouseEvent) {
            var rect = isCustomZoom && !isFullScreen ? customContainerRect : containerRect;
            var topLeftCoords = {};

            if (lastMovementCall && (Date.now() - lastMovementCall) < movementThrottle) {
                return;
            }
            lastMovementCall = Date.now();

            var mouseViewportCoords = {
                x: mouseEvent.clientX - imgRect.left,
                y: mouseEvent.clientY - imgRect.top
            };

            topLeftCoords = getTopLeftCoords(mouseViewportCoords, rect);
            leftOffset = topLeftCoords.leftOffset;
            topOffset = topLeftCoords.topOffset;

            if (currentTopLeft.x === leftOffset && currentTopLeft.y === topOffset) {
                return;
            }

            currentTopLeft.x = leftOffset;
            currentTopLeft.y = topOffset;
            moveZoom(leftOffset, topOffset);
            tryToDownloadNewImages(currentRequestId);

            if (isRegularZoom) {
                self.trigger(self.events.ZOOM, {
                    index: liElemIndex,
                    files: cylindoZoomImagesLoaded,
                    x: getXFraction(),
                    y: getYFraction(),
                });
            }
            else {
                self.trigger(self.events.ZOOM, {
                    index: liElemIndex,
                    url: network.resolveProtocol(img.dataset ? img.dataset.zoomImage : img.getAttribute('data-zoom-image')),
                    x: getXFraction(),
                    y: getYFraction(),
                });
            }
        }
        function tryToDownloadNewImages(refRequestId) {
            if (isRegularZoom) {
                if (!compareGridRectangles(currentGridPos, getCurrentGridRect())) {
                    debouncedfetchZoomedImagePart(refRequestId);
                }
            }
        }
        function fetchZoomedImagePart(refRequestId) {
            detectNewImages(refRequestId);
            drawContents();
        }

        function mouseMoveValidation(evt) {
            self.isPanning = !self.disabled ? true : false;
            clearTimeout(thread);
            mouseMove(evt);
            thread = setTimeout(mousestopped, 100);
        }
        function mousestopped() {
            self.isPanning = false;
        }
        function addEvents() {
            var elContainer = getZoomContainer();

            elContainer.addEventListener('touchstart', dragStart);
            elContainer.addEventListener('touchmove', dragMove);
            elContainer.addEventListener('touchend', dragEnd);
            if (getZoomMode() === zoomModeValues.MOUSE_MOVE ||
                (isCustomZoom && !customZoomForced && !isOverlappingViewer())) {
                if ((isCustomZoom && !customZoomForced && !isOverlappingViewer()) &&
                    getZoomMode() === zoomModeValues.MOUSE_DRAG) {
                    dragDismissed = true;
                    logger.warning('\'mouseDrag\' value assigned to zoomMode property dismissed because zoom contanier is not overlapping the viewer container.');
                }
                elContainer.addEventListener('mousemove', mouseMoveValidation);
                zoomImagery.addEventListener('mouseup', onMouseUp);
                if (isCustomZoom && !customZoomForced) {
                    document.body.addEventListener('mousedown', preventEvents, true);
                    document.body.addEventListener('mouseup', onMouseUp, true);
                }
            }
            else {
                elContainer.addEventListener('mousedown', dragStart);
                elContainer.addEventListener('mousemove', dragMove);
                document.addEventListener('mouseup', dragEnd);
            }

            if (isAutoZoom) {
                document.addEventListener('keyup', isEscBtn);
            }
            eventsAttached = true;
        }
        function removeEvents() {
            var elContainer = getZoomContainer();

            elContainer.removeEventListener('touchstart', dragStart);
            elContainer.removeEventListener('touchmove', dragMove);
            elContainer.removeEventListener('touchend', dragEnd);

            zoomImagery.removeEventListener('mouseup', onMouseUp);
            elContainer.removeEventListener('mousemove', mouseMoveValidation);
            if (isCustomZoom && !customZoomForced) {
                document.body.removeEventListener('mousedown', preventEvents, true);
                document.body.removeEventListener('mouseup', onMouseUp, true);
            }

            elContainer.removeEventListener('mousedown', dragStart);
            elContainer.removeEventListener('mousemove', dragMove);
            document.removeEventListener('mouseup', dragEnd);

            document.removeEventListener('keyup', isEscBtn);
            eventsAttached = false;
        }

        function isEscBtn(evt) {
            var isEscape = false;
            try {
                evt = evt || window.event;
                if ('key' in evt) {
                    isEscape = (evt.key == 'Escape' || evt.key == 'Esc');
                } else {
                    isEscape = (evt.keyCode == 27);
                }
                if (isEscape) {
                    disable();
                }
            }
            catch (e) {
                logger.error('Something went wrong while trying to exit from zoom using ESC button.');
            }
        }

        function setViewerList(list) {
            viewerList = list;
        }

        function onMouseUp(evt, useFractions) {
            var mousePosition;
            preventEvents(evt);
            mousePosition = getGlobalMousePosition();
            isAutoZoom = false;
            if (model.get('maxZoom') === 'mixed' && currentZoomSize !== ZOOM_MAX_SIZE) {
                mousePosition = mousePosition || {};
                mousePosition.currentX = mousePosition.startX = evt.clientX;
                mousePosition.currentY = mousePosition.startY = evt.clientY;
                mousePosition.startTime = evt.timeStamp;
                if (useFractions) {
                    mousePosition.useFractions = true;
                    mousePosition.startXFraction = getXFraction();
                    mousePosition.startYFraction = getYFraction();
                }
                zoomImagery.style.display = 'none';
                startZoom(mousePosition, isFullScreen, globalClickType);
            }
            else {
                disable();
            }
        }

        function zoomIn(evt, data) {
            var mousePosition = {};
            var gblMousePosition = getGlobalMousePosition();
            preventEvents(evt, data);
            if (model.get('maxZoom') === 'mixed' && currentZoomSize !== ZOOM_MAX_SIZE) {
                mousePosition = gblMousePosition ? gblMousePosition : mousePosition;
                isAutoZoom = false;
                zoomImagery.style.display = 'none';
                if (util && util.browser.isMobile()) {
                    mousePosition.withoutCoords = false;
                    mousePosition.useFractions = true;
                    mousePosition.startXFraction = getXFraction();
                    mousePosition.startYFraction = getYFraction();
                }
                startZoom(mousePosition, isFullScreen, globalClickType);
            }
            else {
                disable();
            }
        }
        function zoomOut(evt, data) {
            var mousePosition = {};
            var gblMousePosition = getGlobalMousePosition();
            preventEvents(evt, data);
            if (model.get('maxZoom') === 'mixed' && currentZoomSize === ZOOM_MAX_SIZE) {
                mousePosition = gblMousePosition ? gblMousePosition : mousePosition;
                isAutoZoom = false;
                currentZoomSize = 0;
                zoomImagery.style.display = 'none';
                if (util && util.browser.isMobile()) {
                    mousePosition.withoutCoords = false;
                    mousePosition.useFractions = true;
                    mousePosition.startXFraction = getXFraction();
                    mousePosition.startYFraction = getYFraction();
                }
                startZoom(mousePosition, isFullScreen, globalClickType);
            }
            else {
                disable();
            }
        }

        /**
         * Destroy zoom instance and detach events
         */
        function destroy() {
            clearLayer(zoomImagery);
            setCursor(isFullScreen, useCustomContainer, false);
            cylindoZoomImagesLoaded = [];
            removeEvents();
            if (container.detachEvent) {
                container.detachEvent('onresize', updateContainerRect);
            }
            else if (container.removeEventListener) {
                container.removeEventListener('resize', updateContainerRect);
            }
            else {
                logger.warning('The browser does not support Javascript event binding');
            }
            actionZoomButton.removeEventListener('touchend', zoomIn);
            actionZoomButton2.removeEventListener('touchend', zoomOut);
            container.innerHTML = '';
            pubsub.destroy();
        }
        /**
         * Function to disable zoom if it has started
         * Removes all attached mouse events
         * Triggers DISABLE events for zoom
         * @param {event} evt 
         */
        function disable(evt) {
            var dataIsAlternateIndex, isAlternateImage;
            var imgCtn = viewerList ? viewerList.querySelector('.active') : null;
            if (!imgCtn) {
                return;
            }
            dataIsAlternateIndex = imgCtn.dataset ? imgCtn.dataset.isAlternateImage : imgCtn.getAttribute('is-alternate-image');
            isAlternateImage = imgCtn && parseInt(dataIsAlternateIndex, 10) === 1;
            currentZoomSize = 0;

            stopMoveAutoZoom();

            if (isCustomZoom) {
                if (!initialCustomContainerVisible) {
                    if (customZoomForced) {
                        container.style.display = 'none';
                    }
                    else {
                        dom.fadeOut(container);
                    }
                }
            }

            secondTouchDelta = null;

            setCursor(isFullScreen, useCustomContainer, false);
            preventEvents(evt);
            removeEvents();
            zoomImagery.style.display = 'none';

            zoomImagery.style.willChange = '';
            zoomImagery.style.transition = '';

            self.disabled = true;
            if (self &&
                self.tooltip &&
                self.tooltip.el) {
                self.tooltip.hide(true);
            }

            self.trigger(self.events.DISABLED, {
                index: liElemIndex,
                li: liElem
            });

            cleanAutoZoom();
        }
        function adjustZoomImagery(mousePosition, imgRect, rect) {
            var mouseViewportCoords;
            var tmpLeftOffset = 0;
            var tmpTopOffset = 0;
            var topLeftCoords = {};

            if (!isAutoZoom) {
                mouseViewportCoords = {
                    x: mousePosition.useFractions ? mousePosition.currentX : mousePosition.currentX - imgRect.left,
                    y: mousePosition.useFractions ? mousePosition.currentY : mousePosition.currentY - imgRect.top
                };

            }
            else {
                mouseViewportCoords = {
                    x: fractionToPixels(autoZoomX, true),
                    y: fractionToPixels(autoZoomY, false)
                };
            }
            topLeftCoords = getTopLeftCoords(mouseViewportCoords, rect);
            leftOffset = topLeftCoords.leftOffset;
            topOffset = topLeftCoords.topOffset;
            if (leftOffset > 0) {
                tmpLeftOffset = (leftOffset * -1).toString();
            }
            else if (leftOffset < 0) {
                tmpLeftOffset = (leftOffset * -1 / 2).toString();
            }
            if (topOffset > 0) {
                tmpTopOffset = (topOffset * -1).toString();
            }
            else if (topOffset < 0) {
                tmpTopOffset = (topOffset * -1 / 2).toString();
            }


            if (transformProperty) {
                zoomImagery.style[transformProperty] = 'translate(' + Math.round(tmpLeftOffset) + 'px, ' + Math.round(tmpTopOffset) + 'px)';
            }
            else {
                zoomImagery.style.transform = 'translate(' + Math.round(tmpLeftOffset) + 'px, ' + Math.round(tmpTopOffset) + 'px)';
            }
            currentTopLeft = createPoint(leftOffset, topOffset);
        }
        function getTopLeftCoords(mouseViewportCoords, rect) {
            var leftOffset, topOffset;
            var diffWidth, diffHeight, rightLimit, topLimit;
            var percentX, percentY;
            percentX = mouseViewportCoords.x / imgRect.width;
            percentY = mouseViewportCoords.y / imgRect.height;
            gblPercentX = percentX;
            gblPercentY = percentY;
            if ((isCustomZoom && !useCustomContainer && !isFullScreen)) {
                if (percentX < 0 || percentX > 1) {
                    percentX = Math.max(percentX, 0);
                    percentX = Math.min(percentX, 1);
                }
                if (percentY < 0 || percentY > 1) {
                    percentY = Math.max(percentY, 0);
                    percentY = Math.min(percentY, 1);
                }
                containerRect = container.getBoundingClientRect();
                leftOffset = (percentX * zoomImageryRect.width) - containerRect.width / 2;
                topOffset = (percentY * zoomImageryRect.height) - containerRect.height / 2;
                if (leftOffset < 0 || leftOffset > zoomImageryRect.width) {
                    leftOffset = Math.max(leftOffset, 0);
                    leftOffset = Math.min(leftOffset, zoomImageryRect.width);
                }
                if (topOffset < 0 || topOffset > zoomImageryRect.height) {
                    topOffset = Math.max(topOffset, 0);
                    topOffset = Math.min(topOffset, zoomImageryRect.height);
                }
            }
            else {
                diffWidth = getWidthDifference();
                diffHeight = getHeightDifference();
                rightLimit = getRightLimit();
                topLimit = getTopLimit();
                leftOffset = (rightLimit * percentX) - diffWidth;
                topOffset = (topLimit * percentY) - diffHeight;
            }

            leftOffset = checkHorizontalLimits(leftOffset, rect);
            topOffset = checkVerticalLimits(topOffset, rect);
            return {
                leftOffset: leftOffset,
                topOffset: topOffset
            };
        }
        function processZoom(mousePosition, imgChild, imgRect, refRequestId) {
            var deferred = util.promise.create();
            var rect = isCustomZoom && !isFullScreen ? customContainerRect : containerRect;
            var x, y, subblock;
            var isPartOfFirstGrid = true;
            imgChild = imgChild.parentNode;

            processZoomTimestamp = Date.now();
            if (!mousePosition ||
                (mousePosition && mousePosition.withoutCoords) ||
                (mousePosition &&
                    (isNaN(mousePosition.currentX) || isNaN(mousePosition.currentY)))) {
                mousePosition = {
                    currentX: containerRect.left + (containerRect.width / 2),
                    currentY: containerRect.top + (containerRect.height / 2)
                };
            }

            updateContainerRect();
            if (isRegularZoom && !isSameImage) {
                prepareGrid();
            }
            adjustZoomImagery(mousePosition, imgRect, rect);
            if (!isSameImage) {
                clearLayer(zoomBgLayer);
                clearLayer(zoomImgLayer);
                if (isRegularZoom) {
                    currentGridPos = getCurrentGridRect();
                    currentGridPos.compress(gridRectangle);
                    currentGridPos.print('init grid: ');
                    for (x = currentGridPos.minX; x <= currentGridPos.maxX; x++) {
                        for (y = currentGridPos.minY; y <= currentGridPos.maxY; y++) {
                            subblock = createGridRect(x, y, x, y);
                            downloadGridImage(subblock, refRequestId, isPartOfFirstGrid);
                        }
                    }
                    zoomImgLayer.classList.add(hasPendingGridsClass);
                }
                else {
                    downloadFullImage(mousePosition, imgRect, rect, deferred);
                }
            }
            drawScene();
            addEvents();
            if (isSameImage) {
                if (isRegularZoom) {
                    if (!compareGridRectangles(currentGridPos, getCurrentGridRect())) {
                        detectNewImages(refRequestId);
                    }
                }
                else {
                    downloadFullImage(mousePosition, imgRect, rect, deferred);
                }
            }

            if (isRegularZoom) {
                deferred.resolve();
            }

            deferred.then(function () {
                this.self.disabled = false;
                this.zoomImagery.style.display = '';
                this.zoomImagery.focus();
            }.bind({
                self: self,
                zoomImagery: zoomImagery
            }));
        }

        function cleanUp(isFullScreen) {
            if (!isCustomZoom)
                return;
            zoomImagery.parentNode.removeChild(zoomImagery);
            if (isFullScreen) {
                config.el.appendChild(zoomImagery);
            } else {
                container.appendChild(zoomImagery);
            }
        }

        function setCursor(isFullScreen, useCustomContainer, addClass) {
            if (!isFullScreen &&
                !useCustomContainer &&
                viewerList) {
                if (addClass) {
                    viewerList.classList.add('cylindo-zoom-notcustom-container');
                }
                else {
                    viewerList.classList.remove('cylindo-zoom-notcustom-container');
                }
            }
        }

        function isOverlappingViewer() {
            return useCustomContainer;
        }
        function forceCustomZoom(frame, reloadZoomContainer) {
            var viewerContainerEl = viewPort.querySelector('.cylindo-viewer-container');
            var el = frame ? frame :
                reloadZoomContainer ? viewerContainerEl : viewPort;
            var newCustomZoomContainer = document.createElement('div');
            var recProps = ['width', 'height'];
            var offsetProps = ['top', 'left'];
            var recPropsLen = recProps.length;
            var offsetPropsLen = offsetProps.length;
            var elRect = dom.getRect(el);
            var elOffset = {
                top: frame ? dom.position(el).top : 0,
                left: 0
            };
            var i;
            var timeStamp = new Date().getTime();
            if (!isCustomZoom || customZoomForced) {
                if (customZoomForced) {
                    newCustomZoomContainer = container;
                }
                else {
                    newCustomZoomContainer.setAttribute('id', customZoomID + '-' + timeStamp);
                    newCustomZoomContainer.style.position = 'absolute';
                    newCustomZoomContainer.style.overflow = 'hidden';
                    newCustomZoomContainer.style.display = 'none';
                    initialCustomContainerVisible = false;
                    dom.insertAfter(newCustomZoomContainer, viewerContainerEl);
                    customZoomForced = true;
                }
                for (i = 0; i < recPropsLen; i++) {
                    newCustomZoomContainer.style[recProps[i]] = elRect[recProps[i]] + 'px';
                }
                for (i = 0; i < offsetPropsLen; i++) {
                    newCustomZoomContainer.style[offsetProps[i]] = elOffset[offsetProps[i]] + 'px';
                }
                isCustomZoom = true;
                if (frame || reloadZoomContainer) {
                    viewerContainer = el;
                }
                container = newCustomZoomContainer;
                updateContainerRect();
            }
        }
        function autoZoom(x, y, isFullScreen, frame) {
            var mousePosition = frame ? { frame: frame } : null;
            isAutoZoom = true;
            autoZoomX = x;
            autoZoomY = y;
            startZoom(mousePosition, isFullScreen, 4);
        }
        function cleanAutoZoom() {
            if (isAutoZoom) {
                isAutoZoom = false;
                autoZoomX = 0;
                autoZoomY = 0;
            }
        }
        function clearLayer(el) {
            if (el) {
                el.innerHTML = '';
            }
        }
        function updateZoomImagery(width, height) {
            if (isNaN(width) || isNaN(height)) {
                return;
            }
            zoomImageryRect = {
                width: width,
                height: height
            };
            setElMeasurements(zoomImagery, width, height);
            setElMeasurements(zoomBgLayer, width, height);
            setElMeasurements(zoomImgLayer, width, height);
            drawDebugGrid();
        }
        function setElMeasurements(el, width, height) {
            el.setAttribute('data-width', width);
            el.setAttribute('data-height', height);
            el.style.width = width + 'px';
            el.style.height = height + 'px';
        }
        function getFrameIndex() {
            return self.disabled ? null : liElemIndex;
        }
        function canZoom() {
            var evt = this.evt;
            var data = this.data;
            var list360 = this.list360;
            var viewer = this.viewer;
            var customImageClass = this.customImageClass;
            var preventAllEvents = this.preventAllEvents;
            var isStackedAndNotFS = this.isStackedAndNotFS;
            var customImgZoom = this.customImgZoom;
            var zoom360 = model.get('zoom');
            var alternateContentZoom = model.get('alternateContentZoom');
            var liActive = list360.querySelector('li.active');
            var active = (data && data.frame) ? data.frame : liActive;
            var activeIndex = active ? active.getAttribute('data-index') : null;
            var isAlternativeImg = viewer.viewerPresentation === 'custom' ||
                (active.getAttribute('data-is-alternate-image') ?
                    +active.getAttribute('data-is-alternate-image') : 0) === 1 ?
                true : false;
            var isCustomImg = active.classList.contains(customImageClass);
            var is360Img = !isNaN(activeIndex) ? true : false;
            var zoomURL = active.dataset ? active.dataset.zoomImage : active.getAttribute('data-zoom-image');
            var activeImage = active.childNodes[0];
            var src = activeImage ? activeImage.src ||
                (activeImage.dataset ? activeImage.dataset.src : activeImage.getAttribute('data-src')) : null;
            var viewPortElement = isStackedAndNotFS ? active : list360;
            var viewPortElementRect = viewPortElement.getBoundingClientRect();
            if (isAlternativeImg &&
                active &&
                active.classList &&
                active.classList.contains('hidden')) {
                return false;
            }
            if (zoom360 !== true && alternateContentZoom !== true) {
                return false;
            }
            if (active.classList.contains('cylindo-img-not-found') || active.classList.contains('cylindo-video')) {
                return false;
            }
            if ((evt && preventAllEvents.status) ||
                (is360Img && !zoom360) ||
                (isCustomImg && !customImgZoom) ||
                (isAlternativeImg && !alternateContentZoom)) {
                return false;
            }
            if ((isCustomImg && alternateContentZoom !== true && !customImgZoom)) {
                return false;
            }
            if (zoomURL.indexOf('data:image') === -1) {
                zoomURL = '//' + (zoomURL.split('//')[1]);
            }
            if (zoomURL && src && zoomURL === src.replace(/^http(s)*:/i, '')) {
                if (activeImage.naturalHeight <= viewPortElementRect.height ||
                    activeImage.naturalWidth <= viewPortElementRect.width) {
                    if (self.noZoomTooltip) {
                        self.noZoomTooltip.show();
                        setTimeout(function () {
                            self.noZoomTooltip.hide();
                        }, 4000);
                    }
                    return false;
                }
            }
            return true;
        }
        function moveAutoZoom(x, y) {
            var newCoords;
            x = fractionToPixels(x, true);
            y = fractionToPixels(y, false);
            mouseState = 1;
            newCoords = {
                x: x,
                y: y
            };
            var rect = isCustomZoom && !isFullScreen ? customContainerRect : containerRect;
            var topLeftCoords, desiredLeft, desiredTop, currentLeft, currentTop, left, top, steps, mouseMoveSpeed;
            var currentStep = 0;
            var delay = 16.7;
            var deltaX, deltaY, delta;
            currentLeft = leftOffset;
            currentTop = topOffset;
            topLeftCoords = getTopLeftCoords(newCoords, rect);
            desiredLeft = topLeftCoords.leftOffset;
            desiredTop = topLeftCoords.topOffset;
            deltaX = Math.abs(desiredLeft - currentLeft);
            deltaY = Math.abs(desiredTop - currentTop);
            delta = Math.max(deltaX, deltaY);
            if (delta === 0) {
                logger.warning('Requested coordinates to move zoom are the same coordinates displayed at zoom.');
            }
            mouseMoveSpeed = 20;
            steps = Math.floor(delta / mouseMoveSpeed);
            steps = Math.max(steps, 2);
            stopMoveAutoZoom();
            removeEvents();
            attachMoveAutoZoomEvts();
            moveAutoZoomIntervalId = setInterval(moveZoomImagery, delay);
            function moveZoomImagery() {
                try {
                    ++currentStep;
                    if (currentStep >= steps ||
                        (typeof currentLeft === 'undefined' || typeof desiredLeft === 'undefined') ||
                        (typeof currentTop === 'undefined' || typeof desiredTop === 'undefined')) {
                        stopMoveAutoZoom(true);
                    }
                    else {
                        left = easingHelper.easeOutQuad(currentStep, currentLeft, desiredLeft - currentLeft, steps);
                        top = easingHelper.easeOutQuad(currentStep, currentTop, desiredTop - currentTop, steps);

                        top = Math.round(top * 100) / 100;
                        left = Math.round(left * 100) / 100;
                        leftOffset = left;
                        topOffset = top;
                        currentTopLeft.x = leftOffset;
                        currentTopLeft.y = topOffset;
                        moveZoom(left, top);
                        tryToDownloadNewImages(currentRequestId);
                    }
                }
                catch (ex) {
                    logger.error('Cannot move finish zoom animation', ex);
                    stopMoveAutoZoom();
                }
            }
        }
        function attachMoveAutoZoomEvts() {
            var elContainer = getZoomContainer();
            if (getZoomMode() === zoomModeValues.MOUSE_DRAG) {
                removeStopToDragEvts();
                elContainer.addEventListener('touchend', stopToDrag);
                elContainer.addEventListener('mouseup', stopToDrag);
            }
        }

        function stopToDrag(evt) {
            removeStopToDragEvts();
            if (!self.disabled) {
                if (evt) {
                    evt.preventDefault();
                    evt.stopPropagation();
                }
                stopMoveAutoZoom();
            }
        }
        function removeStopToDragEvts() {
            var elContainer = getZoomContainer();
            elContainer.removeEventListener('touchend', stopToDrag);
            elContainer.removeEventListener('mouseup', stopToDrag);
        }
        function stopMoveAutoZoom(triggerEvent) {
            mouseState = 0;
            removeStopToDragEvts();
            if (moveAutoZoomIntervalId) {
                clearInterval(moveAutoZoomIntervalId);
                moveAutoZoomIntervalId = null;
            }
            if (!eventsAttached) {
                addEvents();
            }
            if (triggerEvent) {
                if (isRegularZoom) {
                    self.trigger(self.events.ZOOM, {
                        index: liElemIndex,
                        files: cylindoZoomImagesLoaded,
                        x: getXFraction(),
                        y: getYFraction(),
                    });
                }
                else {
                    self.trigger(self.events.ZOOM, {
                        index: liElemIndex,
                        url: network.resolveProtocol(img.dataset ? img.dataset.zoomImage : img.getAttribute('data-zoom-image')),
                        x: getXFraction(),
                        y: getYFraction(),
                    });
                }
            }
        }
        function getZoomMode() {
            return model.get('zoomMode');
        }
        function fractionToPixels(value, isWidth) {
            var directionTxt = isWidth ? ' X ' : ' Y ';
            if (typeof value !== 'number') {
                return 0;
            }
            if (value < 0 || value > 1) {
                logger.warning('Invalid value assigned for ' + directionTxt + ' axis (' + value + '). Programatic zoom will be executed at the nearest point.');
            }
            value = Math.max(0, value);
            value = Math.min(1, value);
            if (isWidth) {
                value = value * imgRect.width;
            }
            else {
                value = value * imgRect.height;
            }
            return value;
        }
        function getXFraction() {
            return getCurrentFraction(true);
        }
        function getYFraction() {
            return getCurrentFraction(false);
        }
        function getCurrentFraction(isHorizontal) {
            return (isHorizontal) ?
                checkPercentageLimits(Math.round(gblPercentX * 100) / 100) :
                checkPercentageLimits(Math.round(gblPercentY * 100) / 100);
        }
        function checkPercentageLimits(value) {
            value = Math.max(0, value);
            value = Math.min(1, value);
            return value;
        }
        function getDisplayValue(element) {
            var containerStyle = null;
            containerStyle = getComputedStyle(element);
            return containerStyle && containerStyle.display ? containerStyle.display : null;
        }
        function getGlobalMousePosition() {
            var mousePosition;
            if (globalMousePosition === null ||
                typeof globalMousePosition === 'object') {
                globalMousePosition = globalMousePosition || null;
                mousePosition = JSON.parse(JSON.stringify(globalMousePosition)) || {};
                if (globalMousePosition && globalMousePosition.frame) {
                    mousePosition.frame = globalMousePosition.frame;
                }
                mousePosition = util.object.isEmptyObject(mousePosition) ? null : mousePosition;
            }
            return mousePosition;
        }

        function setZoomInIcon(btn) {
            btn.setAttribute('aria-label', ariaLabel.texts.ENTER_ZOOM);
            btn.classList.remove('cylindo-icon-zoom-on');
            btn.classList.remove('cylindo-icon-zoom-out');
            btn.classList.add('cylindo-icon-zoom-off');
        }

        function setZoomOutIcon(btn) {
            btn.setAttribute('aria-label', ariaLabel.texts.EXIT_ZOOM);
            btn.classList.add('cylindo-icon-zoom-on');
            btn.classList.add('cylindo-icon-zoom-out');
            btn.classList.remove('cylindo-icon-zoom-off');
        }

        function resetCustomZoomBtns() {
            setZoomOutIcon(actionZoomButton);
            actionZoomButton2.style.display = 'none';
        }

        function useMixedZoomBtns() {
            setZoomInIcon(actionZoomButton);
            actionZoomButton2.style.display = 'block';
            setZoomOutIcon(actionZoomButton2);
        }

        function preventEvents(evt, data) {
            if (typeof evt === 'string' &&
                typeof data === 'object') {
                evt = data;
                evt.preventDefault();
                evt.stopPropagation();
            }
            else if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
        }

    };

    Zoom.counter = 0;

    Zoom.prototype.events = {
        READY: 'zoom:ready',
        ZOOM: 'zoom:move',
        ENABLED: 'zoom:enabled',
        DISABLED: 'zoom:disabled'
    };

    var publicAPI = {
        create: function (config) {
            return new Zoom(config);
        }
    };

    window.cylindo.addModule('zoom', publicAPI);
}).call(this);
