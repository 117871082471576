(function () {
    'use strict';

    Logger.LOG = 'log';
    Logger.INFO = 'info';
    Logger.WARNING = 'warning';
    Logger.ERROR = 'error';
    Logger.EVENT = 'event';

    /**
     * Function to concat messages depending on importance
     * Applies function to console by importance
     * Defined importance as INFO, WARNING, ERROR or LOG
     * @param {object} messageObj message object
     */
    function logMessageToConsole(messageObj) {
        var util = cylindo.getModule('cylindo.core.util');
        var importance = messageObj.importance;
        var messageBeginning =  'Cylindo.Logger/' + importance + ' : ';
        var logger;
        var loggerMethod;
        var consoleArguments;
        try {
            consoleArguments = [messageBeginning].concat(messageObj.args);
            switch (importance) {
                case Logger.INFO: {
                    loggerMethod = console.info;
                    break;
                }
                case Logger.WARNING: {
                    loggerMethod = console.warn;
                    break;
                }
                case Logger.ERROR: {
                    loggerMethod = console.error;
                    break;
                }
                case Logger.EVENT:
                case Logger.LOG:
                default:
                    loggerMethod = console.log;
            }
            loggerMethod = loggerMethod || console.log;
            logger = loggerMethod;
            return logger.apply(console, consoleArguments);
        }
        catch (ex) {
            if (console.error) {
                console.error(ex);
            }
            else {
                console.log('Error: ', ex);
            }
            logger = console.log;
            return logger.apply(console, [messageBeginning].concat(messageObj.args));
        }
    }

    function Logger(model) {
        var self = this;
        var messageQueueOptions;
        var loggerLimit;
        if (!model || !model.get) {
            throw new Error('Invalid model instance');
        }        
        loggerLimit = parseInt(typeof model.get('loggerLimit'), 10);
        loggerLimit = isNaN(loggerLimit) ? 100 : loggerLimit;
        messageQueueOptions = {
            loggerLimit: loggerLimit
        };
        this.messageQueue = cylindo.getModule('cylindo.message.queue');
        this.messageQueue = this.messageQueue.create(messageQueueOptions);
        this.isDebugMode = function () {
            var debugVal = model.get('debug');
            return typeof debugVal === 'boolean' ? debugVal : false;
        };
    }

    Logger.prototype.log = function () {
        var args = Array.prototype.slice.call(arguments);
        var messageObj = this.storeMessage(Logger.LOG, args);        
        if (this.isDebugMode()) {
            logMessageToConsole(messageObj);
        }
    };

    Logger.prototype.info = function () {
        var args = Array.prototype.slice.call(arguments);
        var messageObj = this.storeMessage(Logger.INFO, args);        
        if (this.isDebugMode()) {
            logMessageToConsole(messageObj);
        }
    };

    Logger.prototype.warning = function () {
        var args = Array.prototype.slice.call(arguments);
        var messageObj = this.storeMessage(Logger.WARNING, args);        
        if (this.isDebugMode()) {
            logMessageToConsole(messageObj);
        }
    };

    Logger.prototype.error = function () {
        var args = Array.prototype.slice.call(arguments);
        var messageObj = this.storeMessage(Logger.ERROR, args);        
        if (this.isDebugMode()) {
            logMessageToConsole(messageObj);
        }
    };

    Logger.prototype.event = function () {
        var args = Array.prototype.slice.call(arguments);
        var messageObj = this.storeMessage(Logger.EVENT, args);        
        if (this.isDebugMode()) {
            logMessageToConsole(messageObj);
        }
    };

    Logger.prototype.storeMessage = function (importance, args) {
        var messageObj = {};
        messageObj.importance = importance;
        messageObj.args = args;
        switch (importance) {
            case Logger.LOG:
                messageObj.bin = 1;
                break;
            case Logger.INFO:
                messageObj.bin = 1 << 1;
                break;
            case Logger.WARNING:
                messageObj.bin = 1 << 2;
                break;
            case Logger.ERROR:
                messageObj.bin = 1 << 3;
                break;
            case Logger.EVENT:
                messageObj.bin = 1 << 4;
                break;
            default:
                messageObj.bin = 0;
        }
        this.messageQueue.add(messageObj);
        return messageObj;
    };

    Logger.prototype.getDebugInfo = function (search) {
        var messages = [];
        var messagesLen = 0;
        var i;

        if (typeof search === 'number') {
            messages = this.messageQueue.getMessagesByImportance(search);
        }
        else {
            messages = this.messageQueue.getAllMessages();
        }

        messagesLen = messages.length;
        for (i = 0; i < messagesLen; i++) {
            logMessageToConsole(messages[i]);
        }
    };

    var publicAPI = {
        create: function (model) {
            return new Logger(model);
        }
    };
    window.cylindo.addModule('cylindo.util.logger', publicAPI);
}.call(this));
