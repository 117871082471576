(function () {
    'use strict';
    var ViewAbstract = cylindo.getModule('cylindo.classes.abstract.view');
    var SingleViewer = function (options) {
        ViewAbstract.call(this, options);

        var self = this;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = options.dom;
        var scrollHelper = cylindo.getModule('cylindo.helpers.scroll');
        var isTouchEvent = false;
        var isScrolling = false;

        this.util = util;
        this.dom = dom;
        this.wasRotating = false;
        this.rafIndex = null;
        this.isRotating = false;
        this.spinningStartTime = 0;
        this.frameWidth = 512;
        this.mouseState = 0;
        this.rotateSpeed = 50;
        this.framesMoved = 0;
        this.pixelsPerFrame = 0;
        this.endFrame = null;
        this.prevEndFrame = null;
        this.prevIndex = null;
        this.deltaX = 0;
        this.strength = 0;
        this.ease = 3;
        this.ratio = null;
        this.resizeTimer = 0;
        this.pendingImages = [];
        this.isAutoRotating = false;
        this.defaultTooltip = 'tooltipDragText';

        if (options.model.get('missingCombinationErrorText')) {
            this.noFeatureTooltip = cylindo.getModule('tooltip.no-feaure').create({
                parent: options.el,
                text: options.model.get('missingCombinationErrorText'),
                dom: dom
            });
        }

        this.model.on(this.model.events.FEATURES_CHANGED, this.refresh.bind(this));
        this.model.on(this.model.events.FEATURES_ERROR, onFeaturesError);
        this.model.on(this.model.events.FEATURES_NO_WARNING, this.onFeaturesUpdated.bind(this));
        this.model.on(this.model.events.CHANGED, this.updateView.bind(this));
        this.listClassName = 'cylindo-threesixty-list';
        this.viewerPresentation = 'single';

        this.enable = enable.bind(this);
        this.disable = disable.bind(this);
        this.addMouseCallbacks = addMouseCallbacks.bind(this);
        this.removeMouseCallbacks = removeMouseCallbacks.bind(this);

        this.targetLoadedPercentage = this.model.get('partialRotationStart'); 


        if (this.noFeatureTooltip) {
            this.noFeatureTooltip.render();
            if (this.initializedWithError) {
                this.noFeatureTooltip.show();
            }
        }
        this.render();
        function onFeaturesError() {
            if (self && self.noFeatureTooltip) {
                self.noFeatureTooltip.show();
            }
            self.trigger(self.events.FEATURES_ERROR, null);
        }
        /**
         * enable carousel on current viewer instance
         * commonly called and used from view.js
         */
        function enable() {
            addMouseCallbacks.call(this);
            ViewAbstract.prototype.enable.call(this, true);
        }
        function disable() {
            removeMouseCallbacks.call(this);
            ViewAbstract.prototype.disable.call(this, true);
        }
        function addMouseCallbacks() {
            self.wrapper.addEventListener('mouseup', triggerClick);
            self.wrapper.addEventListener('touchstart', touchStart, false);
            self.wrapper.addEventListener('touchmove', touchMove, false);
            self.wrapper.addEventListener('touchend', touchEnd, false);
        }

        function removeMouseCallbacks() {
            self.wrapper.removeEventListener('mouseup', triggerClick);
            self.wrapper.removeEventListener('touchstart', touchStart, false);
            self.wrapper.removeEventListener('touchmove', touchMove, false);
            self.wrapper.removeEventListener('touchend', touchEnd, false);
        }

        function touchStart(evt) {
            scrollHelper.preventScrollX(self.wrapper.parentNode);
            self.setMobileCoords(evt);
        }

        function touchEnd(evt) {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }   
            self.setMobileCoords(evt);
            scrollHelper.allowScrollX(self.wrapper.parentNode);
        }

        function touchMove(evt) {
            self.setMobileCoords(evt);
        }
        function triggerClick(evt) {
            var pos = {};
            pos.currentX = pos.startX = evt.clientX || evt.changedTouches[0].clientX;
            pos.currentY = pos.startY = evt.clientY || evt.changedTouches[0].clientY;
            pos.startTime = evt.timeStamp;

            self.trigger(self.events.CLICKED, pos);
        }

    };

    SingleViewer.prototype = Object.create(ViewAbstract.prototype);
    SingleViewer.prototype.constructor = SingleViewer;

    SingleViewer.prototype.events = ViewAbstract.extendEvents({
        CLICKED: 'single:clicked',
        READY: 'single:ready',
        DOWNLOAD_COMPLETE: 'single:download:complete',
        CURRENT: 'single:current',
        IMG_LOADED: 'single:img:loaded',
        FEATURES_CHANGED: 'single:features:changed',
        FIRST_FRAME_COMPLETE: 'single:firstframe:complete',
        FRAME_ACTIVATED: 'single:frame:activated',
        ALT_CONTENT_REMOVED: 'single:alt:content:removed',
        ALT_CONTENT_LOADED: 'single:alt:content:loaded',
        CONTENT_TYPE_COMPLETE: 'single:content:type:complete',
        FEATURES_CANCELED: 'single:features:canceled',
        FEATURES_ERROR: 'single:features:error'
    });

    SingleViewer.prototype.render = function () {
        ViewAbstract.prototype.tryToCreateTooltip.call(this);
        ViewAbstract.prototype.render.call(this);
    };

    SingleViewer.prototype.isRightClick = function (evt) {
        if ('which' in evt) {
            return (evt.which === 3);
        }
        else if ('button' in evt) {
            return (evt.button === 2);
        }
        return false;
    };
    SingleViewer.prototype.goToIndex = function (index) {
        if (this.framesHandler.is360Frame(this.currentIndex)) {
            this.prevIndex = this.currentIndex;
        }
        ViewAbstract.prototype.goToIndex.call(this, index);
    };

    /**
     * Refresh viewer instance if any feature change
     * Checks valid features and cancel rotation events
     * @method refresh
     */
    SingleViewer.prototype.refresh = function (evt, data) {
        var allListElements;
        var currentElement = this.ul360 ? this.ul360.querySelector('.active') : null;
        var currentActiveIndex;
        var refRequestID;
        var penImagesLen = this.pendingImages.length;

        this.viewerUpdatedStartTime = Date.now();
        refRequestID = data && data.refRequestID ?
            data.refRequestID : this.model.getRequestID();
        this.requestID = refRequestID;

        for (var i = 0; i < penImagesLen; i++) {
            if (refRequestID !== this.pendingImages[i].requestID &&
                this.currentIndex !== this.pendingImages[i].index) {
                this.pendingImages[i].prepareDefer.resolve();
                this.pendingImages[i].img.onload = null;
                this.pendingImages[i].img.onerror = null;
                this.pendingImages[i].img.src = '';
                this.pendingImages.splice(i, 1);
                i--;
                penImagesLen--;
            }
        }

        if (this.framesHandler.isAltId(this.currentIndex)) {
            this.goToIndex(this.prevIndex);
        }
        if (this.isComplete === false) {
            this.trigger(this.events.FEATURES_CANCELED, {
                features: this.model.getCanceledFeatures()
            });
        }
        this.firstFrameIsReady = false;
        this.isReady = false;
        this.isComplete = false;
        this.promises = [];
        this.frameCount = 1;
        this.currentFeatures = this.model.get('currentFeatures');
        this.url = this.currentFeatures.paths;
        allListElements = this.dom.querySelectorAll(this.ul360, 'li:not(.active):not([class*=alt])');
        allListElements.forEach(function (element) {
            element.classList.add('hidden');
            element.innerHTML = '';
        });
        if (currentElement) {
            currentActiveIndex = this.framesHandler.isAltId(this.currentIndex) ?
                this.prevIndex : this.currentIndex;
            this.prepare(currentActiveIndex, refRequestID);
            this.promises = this.framesHandler.is360Frame(currentActiveIndex) ? this.promises : [];
        }

        Promise.all(this.promises).then(function () {
            this.refreshRest(refRequestID);
            this.onFeaturesUpdated.call(this);
        }.bind(this));
    };
    SingleViewer.prototype.updateView = function (evt, data) {
        this.showTooltip(this.model.get(this.defaultTooltip));
    };
    SingleViewer.prototype.refreshRest = function (requestID) {
        this.promises = [];
        this.downloadRest(requestID);
        Promise.all(this.promises).then(this.onFeaturesUpdated.bind(this));
    };
    SingleViewer.prototype.onFeaturesUpdated = function () {
        if (!this.model.get('hasUnsupportedFeatures')) {
            if (this.noFeatureTooltip) {
                this.noFeatureTooltip.hide();
            }
        }
        this.trigger(this.events.FEATURES_CHANGED);
    };

    SingleViewer.prototype.downloadRest = function (requestID) {
        var startFrame = this.model.get('startFrame');
        if (startFrame !== this.currentIndex) {
            this.prepare(startFrame, requestID);
        }
    };

    SingleViewer.prototype.prepare = function (index, requestID) {
        var self = this;
        var frameDeferred = this.util.promise.create();
        var deferred = this.util.promise.create();
        var baseClassName, className, li, img, totalFrames, imgTimeout, liSelection, integerIndex;

        if (this.framesHandler.is360Frame(index)) {
            baseClassName = 'cylindo-viewer-frame-';
            className = baseClassName + index;
            li = document.createElement('li');
            li.setAttribute('aria-hidden', true);
            img = new Image();
            totalFrames = this.frameCount;
            imgTimeout = this.model.get('imageTimeout');

            liSelection = this.dom.querySelectorAll(this.ul360, '.' + className);
            if (liSelection && liSelection.length >= 1) {
                li = liSelection[liSelection.length - 1];
            }

            li.classList.add(baseClassName + index);

            if (this.url) {
                var rtvOptions = {
                    requestID: requestID,
                    viewer: this,
                    urls: this.url,
                    baseClassName: baseClassName,
                    index: index,
                    li: li,
                    totalFrames: totalFrames,
                    imgTimeout: imgTimeout,
                    onImageLoad: this.onImageLoad,
                    onError: this.onError,
                    prepareDefer: deferred
                };

                frameDeferred = this.renderer.useStrategy(this.renderer.strategies.RTV_SINGLE_MERGED, rtvOptions);
            }
            else {
                img.src = '';
            }

            this.promises.push(deferred);

            if (index === this.currentIndex && !li.classList.contains('active')) {
                this.handleActiveFrame(li, true);
            }
            if (this.ul360) {
                this.ul360.appendChild(li);
            }    
        }
        else if (this.framesHandler.isAltId(index)) {
            integerIndex = parseInt(index.replace(this.framesHandler.prefixes.ALT, ''), 10);
            frameDeferred = this.prepareAltContent(integerIndex);
        }
        frameDeferred.then(function () {
            self.firstFrameEvt(index);
            deferred.resolve();
        });

        return deferred;
    };

    SingleViewer.prototype.reloadAltContent = function (evt, data) {
        var allFrames = this.framesHandler.getAllFramesToBeLoaded();
        var altContentLen = allFrames.ALTERNATE.LEN;
        var i;
        var prevIndex = this.prevIndex || 1;
        prevIndex = isNaN(prevIndex) ? prevIndex : parseInt(prevIndex, 10);
        ViewAbstract.prototype.reloadAltContent.call(this, evt, data);
        this.prevIndex = prevIndex;
        this.goToIndex(this.prevIndex);
        for (i = 0; i < altContentLen; i++) {
            this.prepareAltContent(i, evt, data);
        }
    };

    SingleViewer.prototype.onReady = function () {
        ViewAbstract.prototype.onReady.call(this);
        ViewAbstract.prototype.sendFirstFrameCompleteEvt.call(this);
        this.tryToShowTooltip();
    };
    SingleViewer.prototype.onImageLoad = function () {
        var li = this.li;
        var clonedLi = li.cloneNode();
        var img = this.img;
        var layers = this.layers;
        var that = this.self;
        var deferred = this.deferred;
        var imagesArrLen = img.length;
        var divImgContainer;
        var altText = 'Frame ' + this.index + ' of "__productName__" ';
        var pendingImageIndex = -1;
        var i;


        for (i = 0; i < that.pendingImages.length; i++) {
            if (that.pendingImages[i].index === this.index) {
                pendingImageIndex = i;
            }
        }
        if (pendingImageIndex !== -1) {
            that.pendingImages.splice(pendingImageIndex, 1);
        }
        if (this.requestID !== that.model.getRequestID()) {
            return;
        }

        if (this.index === that.currentIndex ||
            (!that.framesHandler.is360Frame(this.currentIndex) && this.index === that.startFrame)) {
            that.firstImageLoadedTime = Date.now();
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        altText = altText.replace('__productName__', that.model.get('fileName'));

        if (img.length > 1) {
            for (i = 0; i < imagesArrLen; i++) {
                img[i].alt = altText + '(' + layers[i].type + ' layer - ' + layers[i].code + ')';
                clonedLi.appendChild(img[i]);
            }
        }
        else {
            if (img instanceof Image) {
                img.alt = altText;
                clonedLi.appendChild(img);
            } else {
                img[0].alt = altText;
                clonedLi.appendChild(img[0]);
            }
        }

        that.ul360.appendChild(clonedLi);
        that.dom.remove(li);

        that.tryToEnableImageGroup();
        that.trigger(that.events.IMG_LOADED, { sequence: this.index, img: img });
        deferred.resolve();
    };

    SingleViewer.prototype.tryToEnableImageGroup = function () {
        var self = this;
        var addedFrames = this.dom.querySelectorAll(this.ul360, '[class*="cylindo-viewer-frame-"]:not([class*="-alt-"])');
        addedFrames = addedFrames.filter(function (element) {
            var className = element.className.match(/cylindo-viewer-frame-(\d+)/ig)[0];
            var occurrences = self.dom.querySelectorAll(self.ul360, '.' + className);
            return element.childNodes.length !== 0 &&
                addedFrames.indexOf(element) === addedFrames.indexOf(occurrences[occurrences.length - 1]);
        });
        addedFrames.forEach(function (element) {
            element.classList.remove('hidden');
        });
        if (!this.isVisible && addedFrames.length > 0) {
            this.displayViewer();
        }
        if (!this.isReady && addedFrames.length > 0) {
            this.onReady();
        }
        if (!this.isComplete && addedFrames.length > 0) {
            this.onDownloadComplete();
        }
    };

    SingleViewer.prototype.tryToShowTooltip = function () {
        var currElement = this.ul360.querySelector('.active');
        var activeIndex = currElement.getAttribute('data-index');
        var is360Frame = this.framesHandler.is360Frame(activeIndex);
        var isAltContent = this.framesHandler.isAltId(activeIndex);
        var isVideo = currElement.classList.contains('cylindo-video');
        var isImgNotFound = currElement.classList.contains('cylindo-img-not-found');
        var threeSixtyText = this.model.get('tooltipDragText');
        var altltImgText = this.model.get('tooltipAltImgText');
        var showTooltip = true;
        if (isImgNotFound || isVideo) {
            showTooltip = false;
        }
        if (this.tooltip && !this.tooltip.isVisible() && showTooltip) {
            if (is360Frame) {
                if (typeof threeSixtyText === 'string' &&
                    threeSixtyText.length > 0) {
                    this.showTooltip(threeSixtyText);
                }
            }
            else if (isAltContent) {
                if (typeof altltImgText === 'string' &&
                    altltImgText.length > 0) {
                    this.showTooltip(altltImgText);
                }
            }
        }
    };

    var publicAPI = {
        create: function (opts) {
            return new SingleViewer(opts);
        }
    };

    window.cylindo.addModule('singleViewer', publicAPI);
}).call(this);
