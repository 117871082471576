
(function () {
    'use strict';
    /**
     * Abstract function for button instance
     * @param {array} opts 
     */
    var AbstractButton = function (opts) {
        opts = opts || {};
        var appendButton = typeof opts.appendButton === 'boolean' ? opts.appendButton : true;
        var linkButton = opts.linkButton;
        var util = cylindo.getModule('cylindo.core.util');
        var dom = opts.dom;
        this.appendButton = appendButton;
        this.linkButton = linkButton;
        this.util = util;
        this.dom = dom;
        this.text = opts.text || '';
        this.btn = null;
        this.parentEl = opts.parent || document.getElementsByTagName('body')[0];
        this.initialized = true;
        this.el = document.createElement('div');
        this.btn = this.linkButton ?
        document.createElement('a') :
        document.createElement('button');
    };

    AbstractButton.prototype.render = function (data) {
        var self = this;
        if (self.wrapperClasses && self.wrapperClasses instanceof Array) {
            self.wrapperClasses.forEach(function (wrapperClass) {
                self.el.classList.add(wrapperClass);
            });
        }
        self.parentEl.appendChild(self.el);
        if (self.appendButton) {
            self.el.appendChild(self.btn);
        }
    };
    AbstractButton.prototype.remove = function () {
        var self = this;
        self.dom.remove(self.el);
    };
    AbstractButton.prototype.show = function (text, immediate) {
        var self = this;
        if (immediate) {
            self.dom.fadeIn(self.el, 0);
        }
        else {
            self.dom.fadeIn(self.el);
        }
    };
    AbstractButton.prototype.hide = function (immediate) {
        var self = this;
        if (immediate) {
            self.dom.fadeOut(self.el, 0);
        }
        else {
            self.dom.fadeOut(self.el);
        }
    };
    AbstractButton.prototype.setParent = function (element) {
        var self = this;
        self.parentEl = element;
    };
    AbstractButton.prototype.isVisible = function () {
        var self = this;
        return self.el.style.display === 'block';
    };

    window.cylindo.addModule('cylindo.classes.button', AbstractButton);
}.call(this));
