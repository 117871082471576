(function () {
    'use strict';
    var Viewer = function () {
        var instances = {};
        var viewerServices = cylindo.getModule('cylindo.viewer.services');
        var viewerProperties = cylindo.getModule('cylindo.viewer.properties');

        this.services = {
            skuExists: viewerServices.skuExists 
        };
        this.create = function (opts) {
            var util = cylindo.getModule('cylindo.core.util');
            var instance = null;
            if (opts && viewerProperties.areRequiredPropertiesPresent(opts)) {
                viewerProperties.reportUnknownProperties(opts);

                if (opts.containerID) {
                    opts.containerID = opts.containerID.replace(/^#/, '');
                }

                if (opts.hasOwnProperty('SKU')) {
                    opts.productCode = opts.SKU;
                    delete opts.SKU;
                }
                if (opts.hasOwnProperty('skuVersion')) {
                    opts.version = opts.skuVersion;
                    delete opts.skuVersion;
                }
                if (opts.hasOwnProperty('imageResolution')) {
                    opts.size = opts.imageResolution;
                    delete opts.imageResolution;
                }

                try {
                    instance = cylindo.getModule('cylindo.controller').create(opts);
                    instances[opts.productCode + '_' + opts.containerID] = instance;
                    instances[opts.productCode + '_' + opts.containerID].on(instance.events.DESTROYED, function () {
                        delete instances[opts.productCode + '_' + opts.containerID];
                    });
                }
                catch (e) {
                    console.error(e);
                }
                return instance;
            }
            else {
                throw 'Cylindo 360 HD Viewer require a valid options object to instantiate. Please refer to the integration guide';
            }
        };

        this.getInstances = function () {
            return instances;
        };

        this.getViewer = function (id) {
            return instances[id] || null;
        };

        this.destroyAll = function () {
            for (var index in instances) {
                if (instances.hasOwnProperty(index)) {
                    instances[index].destroy();
                }
            }
            instances = {};
        };

        this.update = function (instanceID, field, params) {
            var instance = instances[instanceID];
            if (!instance) {
                throw Error('Instance doesn\'t exist');
            }
            instance.hideImage();
            instance.update(field, params);
        };

        this.destroy = function (instanceID) {
            var instance = instance[instanceID];
            if (!instance) {
                throw Error('Instance doesn\'t exist');
            }
            instance.destroy();
            instance = null;
            delete instances[instanceID];
        };
    };

    window.cylindo.addModule('viewer', new Viewer(), true);
}).call(this);
